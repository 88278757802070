import React from 'react';
import {Text} from "@consta/uikit/Text";
import {useParams} from "react-router-dom";
import {Table} from "@consta/uikit/Table";
import classes from "./TripPage.module.css";
import useGetTrip from "../../utils/useGetTrip";
import TripDictionary from "../../utils/Dictionaries/TripDictionary";
import {Card} from "@consta/uikit/Card";
import {Layout} from "@consta/uikit/Layout";
import AviaItemCard from "../../Components/TripComponents/AviaItemCard";


// импорты иконок для типов услуг
import {AviaIcon} from "../../Components/Icons/AviaIcon";
import {HotelIcon} from "../../Components/Icons/HotelIcon";
import {TrainIcon} from "../../Components/Icons/TrainIcon";
import {TaxiIcon} from "../../Components/Icons/TaxiIcon";
import { IconTable } from '@consta/icons/IconTable';
import { IconPaste } from '@consta/icons/IconPaste';
import {TrasferIcon} from "../../Components/Icons/TransferIcon";
import {VipHallIcon} from "../../Components/Icons/VipHallIcon";
import {EmdIcon} from "../../Components/Icons/EmdIcon";
// добавить инонку для доп услуг авиа


const TripPage = () => {

    const params = useParams()
    const {trip, parsedJSONData} = useGetTrip()

    const getItemIcon = (serviceType) => {
        switch (serviceType) {
            case "Air":
                return <AviaIcon/>
            case "Hotel":
                return <HotelIcon/>
            case "Train":
                return <TrainIcon/>
            case "Transfer":
                return <TrasferIcon/>
            case "VipHall":
                return <VipHallIcon/>
            case "Taxi":
                return <TaxiIcon/>
            case "Custom":
                return <IconTable/>
            case "FlightCertificate":
                return <IconPaste/>
            case "AirAdditionalService":
                return <EmdIcon size="l"/>
        }
    }

    const rows = trip.OrderItems.map(item =>{
        const checkinDate = new Date(item.ActualVersion.CheckinDate)
        const checkoutDate = new Date(item.ActualVersion.CheckoutDate)
        const eventDate = new Date(item.ActualVersion.EventDate)
        const employees = item.ActualVersion.Employees.map(emp=>emp.ShortRuName)
        return {
            TripItemId: item.Id,
            Name: trip.Name, // Направление
            ServiceType: item.ActualVersion.ServiceType, // переделал под иконки
            CheckinDate: { // дата начала
                date: checkinDate.toLocaleString("ru", {dateStyle:"long"}),
                time: checkinDate.toLocaleString("ru", {timeStyle:"short"})
            },
            CheckoutDate: { // дата начала
                date: checkoutDate.toLocaleString("ru", {dateStyle:"long"}),
                time: checkoutDate.toLocaleString("ru", {timeStyle:"short"})
            }, // дата окончания
            EventDate: { // дата начала
                date: eventDate.toLocaleString("ru", {dateStyle:"long"}),
                time: eventDate.toLocaleString("ru", {timeStyle:"medium"})
            }, // дата создания
            CreatedBy: item.ActualVersion.CreatedBy, // создатель услуги
            Employees: employees, // пассажиры
            Price: item.ActualVersion.Price.toLocaleString("ru") + " ₽", // цена
            Company: {
                Company: item.ActualVersion.CompanyId,
                Department: item.ActualVersion.DepartmentId,
                Project: item.ActualVersion.ProjectId
            }, // пока что айдишникию. потом сделаю названия
            Status: TripDictionary.tripStatus[item.ActualVersion.StatusExplanation], //статус услуги, потом сделать русское название через словарь
            id: item.Id +""
        }
    })
    console.log(rows)


    const columns = [
        {
            title:"Trip Item",
            accessor: "TripItemId",
            width: 100
        },
        {
            title:"Название",
            accessor: "Name",
        },
        {
            title:"",
            accessor: "ServiceType",
            renderCell: (item) => {
                return getItemIcon(item.ServiceType)

            },
            width: 70
        },
        {
            title:"Дата начала",
            accessor: "CheckinDate",
            renderCell: (item) => {
                return (
                    <div>
                        <Text view="primary" size="s">{item.CheckinDate.date}</Text>
                        <Text view="primary" size="xs">{item.CheckinDate.time}</Text>
                    </div>
                )
            },
            width: 170
        },
        {
            title:"Дата окончания",
            accessor: "CheckoutDate",
            renderCell: (item) => {
                return (
                    <div>
                        <Text view="primary" size="s">{item.CheckoutDate.date}</Text>
                        <Text view="primary" size="xs">{item.CheckoutDate.time}</Text>
                    </div>
                )
            },
            width: 170
        },
        {
            title:"Дата создания",
            accessor: "EventDate",
            renderCell: (item) => {
                return (
                    <div>
                        <Text view="primary" size="s">{item.EventDate.date}</Text>
                        <Text view="primary" size="xs">{item.EventDate.time}</Text>
                    </div>
                )
            },
            width: 170
        },
        {
            title:"Кто создал",
            accessor: "CreatedBy",
            renderCell: (item) => {
                return (
                    <div>
                        <Text view="primary" size="s">{item.CreatedBy}</Text>
                    </div>
                )
            },
        },
        {
            title:"Пассажиры",
            accessor: "Employees",
            renderCell: (item) => {
                return (
                    <div>
                        {item.Employees.map(employee => {
                            return (
                                <Text view="primary" size="xs">{employee}</Text>
                            )
                        })}
                    </div>
                )


            }
        },
        {
            title:"Стоимость",
            accessor: "Price",
            width: 125
        },
        {
            title:"Компания",
            accessor: "Company",
            renderCell: (item) => {
                return (
                    <div>
                        <Text view="primary" size="xs">Компания ({item.Company.Company})</Text>
                        <Text view="primary" size="xs">Отдел ({item.Company.Department})</Text>
                        <Text view="primary" size="xs">Центр затрат ({item.Company.Project})</Text>
                    </div>
                )
            }
        },
        {
            title:"Статус",
            accessor: "Status",
        },

    ]

    const date = new Date (trip.Date)
// toLocaleString("ru", {dateStyle:"long"})
// toLocaleString("ru", {timeStyle:"medium"})
    return (
        <div className={classes.App}>
            <div style={{height:"10%", width:"100%"}}>
                <div id="HeaderE" style={{padding:"20px 20px 0 20px"}}>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Text size="2xl" view="primary" lineHeight="2xs" style={{marginRight:"10px"}}>{trip.Name}</Text>
                        <Text size="2xl" view="primary" lineHeight="2xs" style={{marginRight:"10px"}}>-</Text>
                        <Text size="2xl" view="primary" lineHeight="2xs" style={{marginRight:"40px"}}>{trip.TripId}</Text>
                        <Text size="2xl" view="primary" lineHeight="2xs" style={{marginRight:"40px"}}>{TripDictionary.tripStatus[trip.Status]}</Text>
                        <Text size="2xl" view="primary" lineHeight="2xs" style={{marginRight:"25px"}}>{trip.TotalAmount} ₽</Text>
                    </div>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Text size="s" view="secondary" style={{marginRight:"20px"}}>Автор: {trip.UserName}</Text>
                        <Text size="s" view="secondary" style={{marginRight:"20px"}}>-</Text>
                        <Text size="s" view="secondary" style={{marginRight:"20px"}}>{date.toLocaleString("ru", {dateStyle:"long"})} {date.toLocaleString("ru", {timeStyle:"medium"})}</Text>
                    </div>
                </div>
            </div>
            <div style={{height:"37%", width:"100%", padding:"0 10px 15px 10px"}}>
                <Table columns={columns} rows={rows} zebraStriped="odd" stickyHeader={true} onCellClick={({rowId})=>{
                    console.log(rowId)}}/>
            </div>
            <div style={{height:"53%", width:"100%"}}>
                <Layout direction="row">
                    <Layout fixed flex={4} className={classes.Layout1} direction="row">
                        <Card style={{height:"100%", width:"100%"}} border={true}>
                            <Text view="primary">Версии по услуге</Text>
                        </Card>
                    </Layout>
                    <Layout flex={4} className={classes.Layout1} direction="row">
                        <AviaItemCard/>
                    </Layout>
                </Layout>
            </div>
        </div>
    );
};

export default TripPage;