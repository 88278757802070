import React, {useState} from 'react';
import {presetGpnDark, presetGpnDefault, presetGpnDisplay} from "@consta/uikit/Theme";

import {IconSun} from "@consta/icons/IconSun";
import {IconMoon} from "@consta/icons/IconMoon";
import {IconEye} from "@consta/icons/IconEye";

const UseTheme = () => {

    const items = ["Светлая","Темная","Акцентная"]
    const getItemIcon = (item) => {
        if (item === 'Светлая') {
            return IconSun;
        }
        if (item === 'Темная') {
            return IconMoon;
        }
        return IconEye;
    };
    const getTheme = (item) => {
        if (item === 'Светлая') {
            return presetGpnDefault;
        }
        if (item === 'Темная') {
            return presetGpnDark;
        }
        return presetGpnDisplay;
    };

    const getSavedTheme = () => {
        const theme = localStorage.getItem("AltBackTheme")
        if (theme) {
            return parseInt(theme)
        } else {
            localStorage.setItem("AltBackTheme", "1")
            return parseInt(localStorage.getItem("AltBackTheme"))
        }
    }
    const [value, setValue] = useState(items[getSavedTheme()]);



    return {items, getItemIcon, value, setValue, getTheme}
};


// ворзвращаем
export default UseTheme;