import React, {useState} from 'react';
import {Text} from "@consta/uikit/Text";
import {Layout} from "@consta/uikit/Layout";
import classes from "./TemplatePage.module.css";
import {Card} from "@consta/uikit/Card";
import AuthModal from "../../Components/AuthClientSessionModal/AuthModal";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import {IconUser} from "@consta/icons/IconUser";
import {IconBento} from "@consta/icons/IconBento";
import {IconCopy} from "@consta/icons/IconCopy";
import ClsModal from "../../Components/AuthClientSessionModal/ClsModal";
import {ThemeToggler} from "@consta/uikit/ThemeToggler";
import {BookmarkTabs} from "@consta/uikit/BookmarkTabs";
import {TabContext} from "../../utils/TabContext";
import useTabs from "../../utils/useTabs";
import logo from "../../static/logo.png"

const TemplatePage = ({themeItems, getItemIcon, themeValue, setThemeValue}) => {


    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [isClSModalOpen, setIsClSModalOpen] = useState(false);
    const openBackOffice = () => {
        //более сложная логика с открытием в бо текущей вкладки
        console.log("Открываем вкладку в БО")
        window.open("https://bo.smartway.today/")
    }
    const {tabs, createTab, removeTab, activeTab, HandleChangeTab, addTabWithButton} = useTabs()



    return (

        <div className={classes.Template}>
            <AuthModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen}/>
            <ClsModal isModalOpen={isClSModalOpen} setIsModalOpen={setIsClSModalOpen}/>
            <Layout direction="column">
                <Layout direction="row" style={{ width:"100vw", height:"5%"}}>
                    <Layout style={{width:"4.16%", height:"100%"}}>
                        <Card className={classes.TemplateCard} shadow={false} style={{display:"flex", justifyContent:"center"}}>
                            <img src={logo} style={{width:"42px", height:"42px"}}/>
                        </Card>
                    </Layout>
                    <Layout style={{width:"95.83%", height:"100%"}}>
                        <Card className={classes.TemplateCard}>
                            <BookmarkTabs
                                items={tabs}
                                value={activeTab}
                                onChange={HandleChangeTab}
                                view="clear"
                                withNavigationButtons={true}
                                onCreate={addTabWithButton}
                                onRemove={removeTab}
                            />
                        </Card>
                    </Layout>
                </Layout>
                <Layout direction="row" style={{width:"100vw", height:"95%"}}>
                    <Layout style={{width:"4.16%", height:"100%"}}>
                        <Card className={classes.TemplateCard} border={true} style={{padding:"15px 0px"}}>
                            <Grid cols="1" xAlign="center" gap="l">
                                <GridItem>
                                    <Button label="Авторизация" view="clear" iconLeft={IconUser} onlyIcon form="round" iconSize="l" onClick={()=>{setIsAuthModalOpen(true)}}/>
                                </GridItem>
                                <GridItem>
                                    <Button label="Отрыть БО" view="clear" iconLeft={IconCopy} onlyIcon form="round" iconSize="l" onClick={openBackOffice}/>
                                </GridItem>
                                <GridItem>
                                    <Button label="Клиентская сессия" view="clear" iconLeft={IconBento} onlyIcon form="round" iconSize="l" onClick={()=>{setIsClSModalOpen(true)}}/>
                                </GridItem>
                                <GridItem>
                                    <ThemeToggler
                                        items={themeItems}
                                        value={themeValue}
                                        getItemKey={(item) => item}
                                        getItemLabel={(item) => item}
                                        getItemIcon={getItemIcon}
                                        onChange={(value)=>{
                                            setThemeValue(value)
                                            localStorage.setItem("AltBackTheme", themeItems.indexOf(value).toString())
                                        }}
                                        direction="downStartLeft"
                                        size="m"
                                        form="round"
                                    />
                                </GridItem>
                            </Grid>
                        </Card>
                    </Layout>
                    <Layout style={{width:"95.83%"}}>
                        <TabContext.Provider value={createTab}>
                            {activeTab.content}
                        </TabContext.Provider>
                    </Layout>
                </Layout>
            </Layout>

        </div>
    );
};

export default TemplatePage;