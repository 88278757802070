import React from 'react';
import classes from "./EmployeePage.module.css"
import {useParams} from "react-router-dom";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Layout} from "@consta/uikit/Layout";
import {Card} from "@consta/uikit/Card";
import EmployeeMain from "../../Components/EmpolyeeComponents/Main/EmployeeMain";
import CompanyMain from "../../Components/EmpolyeeComponents/Company/CompanyMain";
import TravelPolicy from "../../Components/EmpolyeeComponents/TravelPolicy/TravelPolicy";
import RulesMain from "../../Components/EmpolyeeComponents/UserRules/RulesMain";
import {Loader} from "@consta/uikit/Loader";
import {Text} from "@consta/uikit/Text";
import useGetEmployee from "../../utils/useGetEmployee";

const EmployeePage = () => {

    const params = useParams()
    const employeeObj = useGetEmployee(params.id)


    const GetEmployeeMain = () => {
        if (!employeeObj || employeeObj.employee.isLoading) return (
            <div style={{height:"100%", width:"100%", display: "flex", alignItems:"center", justifyContent:"center"}}>
                <Loader/>
            </div>
        )
        if (employeeObj.employee.error) return

        if (employeeObj.employee.isSuccess) return <EmployeeMain employee={employeeObj.employee.data}/>

    }




    const GetCompanyMain = () => {

        if (employeeObj.employee.isLoading) return (
            <div style={{height:"100%", width:"100%", display: "flex", alignItems:"center", justifyContent:"center"}}>
                <Loader/>
            </div>
        )
        if (employeeObj.employee.error) return

        if (employeeObj.employee.isSuccess) return <CompanyMain companies={employeeObj.employee.data.Companies} project={employeeObj.project.data} AccountName={employeeObj.employee.data.AccountName} AccountId={employeeObj.employee.data.AccountId}/>
    }

    const GetTravelPolicy = () => {

        if (employeeObj.employee.isLoading || employeeObj.travelPolicy.isLoading) return (
            <div style={{height:"100%", width:"100%", display: "flex", alignItems:"center", justifyContent:"center"}}>
                <Loader/>
            </div>
        )
        if (employeeObj.travelPolicy.error) return


        if (employeeObj.travelPolicy.isSuccess) {
            if (employeeObj.travelPolicy.data === "404")  return <Text view="primary" size="xl" lineHeight="2xs" style={{margin:"20px"}}>Нет тревел политики</Text>
            return <TravelPolicy travelPolicy={employeeObj.travelPolicy.data}/>

        }

    }

    const GetRulesMain = () => {

        if (employeeObj.employee.isLoading || employeeObj.rules.isLoading || employeeObj.user.isLoading || employeeObj.approvers.isLoading || employeeObj.schemeApprovers.isLoading || employeeObj.approvalScheme.isLoading || employeeObj.erApprovers.isLoading || employeeObj.erApprovalScheme.isLoading) return (
            <div style={{height:"100%", width:"100%", display: "flex", alignItems:"center", justifyContent:"center"}}>
                <Loader/>
            </div>
        )

        if (employeeObj.rules.error) return

        if (employeeObj.rules.isSuccess) {

            if (employeeObj.rules.data === "404") return <Text view="primary" size="xl" lineHeight="2xs" style={{margin:"20px"}}>Нет доступа в сервис</Text>

            return <RulesMain rules={employeeObj.rules.data} user={employeeObj.user.data} approvers={employeeObj.approvers.data} erApprovers={employeeObj.erApprovers.data} approvalScheme={employeeObj.approvalScheme.data} schemeApprovers={employeeObj.schemeApprovers.data} erApprovalScheme={employeeObj.erApprovalScheme.data.scheme} erSchemeApprovers={employeeObj.erApprovalScheme.data.approvers}/>
        }


    }

    return (
        <div className={classes.App}>
            <Grid cols="3" style={{height:"100%", width:"100%"}}>
                <GridItem>
                    <Layout direction="column">
                        <Layout fixed flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <GetEmployeeMain/>
                            </Card>
                        </Layout>
                        <Layout flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <GetCompanyMain/>
                            </Card>
                        </Layout>
                    </Layout>
                </GridItem>
                <GridItem >
                    <Layout>
                        <Layout flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <GetTravelPolicy/>
                            </Card>
                        </Layout>
                    </Layout>
                </GridItem>
                <GridItem>
                    <Layout>
                        <Layout flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <GetRulesMain/>
                            </Card>
                        </Layout>
                    </Layout>
                </GridItem>
            </Grid>
        </div>
    );
};

export default EmployeePage;