import React, {useEffect, useState} from 'react';
import {Text} from "@consta/uikit/Text";
import AviaTPMain from "./AviaTP/AviaTPMain";
import HotelTPMain from "./HotelTP/HotelTPMain";
import TrainTPMain from "./TrainTP/TrainTPMain";
import TaxiTPMain from "./TaxiTP/TaxiTPMain";
import TransferTPMain from "./TransferTP/TransferTPMain";
import VIPHallTPMain from "./VIPHallTP/VIPHallTPMain";
import axios from "axios";
import {Loader} from "@consta/uikit/Loader";
import {Informer} from "@consta/uikit/Informer";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {useApiGET} from "../../../utils/useAPI";

const TravelPolicy = ({travelPolicy}) => {
    return (
        <div style={{padding:"20px"}}>
            <div id="HeaderC" style={{paddingBottom:"10px"}}>
                <Text size="xl" view="primary" lineHeight="2xs">{travelPolicy.Name}</Text>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <Text size="xs" style={{marginRight:"10px", paddingBottom:"8px"}} view="secondary">{travelPolicy.Description}</Text>
                </div>
            </div>
            <div style={{overflowY:"scroll", height:"calc(90%)", maxHeight: "80vh"}}>
                <Grid cols={1} rowGap="s">
                    <GridItem>
                        <AviaTPMain AirlineRules={travelPolicy.AirlineRule}/>
                    </GridItem>
                    <GridItem>
                        <TrainTPMain TrainRule={travelPolicy.TrainRule}/>
                    </GridItem>
                    <GridItem>
                        <HotelTPMain HotelRule={travelPolicy.HotelRule}/>
                    </GridItem>
                    <GridItem>
                        <TaxiTPMain TaxiVoucherRule={travelPolicy.TaxiVoucherRule}/>
                    </GridItem>
                    <GridItem>
                        <TransferTPMain TransferVoucherRule={travelPolicy.TransferVoucherRule}/>
                    </GridItem>
                    <GridItem>
                        <VIPHallTPMain VipHallRule={travelPolicy.VipHallRule}/>
                    </GridItem>
                </Grid>
            </div>
        </div>
    );

};

export default TravelPolicy;


