import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {Button} from "@consta/uikit/Button";

const AviaItemDetails = () => {
    return (
        <div style={{marginTop:"30px", width:"100%"}}>
            <Grid cols={3}>
                <GridItem>
                    <div>
                        <Text view="primary" lineHeight="l" align="left" size="xl">Стоимость - Х рублей</Text>
                        <Text view="primary" align="left" size="xs">НДС - Y рублей</Text>
                    </div>
                </GridItem>
                <GridItem>
                    <div>
                        <Text view="primary" align="center" size="s">Код перевозчика</Text>
                        <Text view="primary" lineHeight="l" align="center" size="s">Провайдер (или поставщик?)</Text>
                    </div>
                </GridItem>
                <GridItem>
                    <div>
                        <Text view="primary" lineHeight="l" align="right" size="xl">Фамилия И.О.</Text>
                        <Text view="primary" align="right" size="xs">Компания (ID) - отдел</Text>
                        <Text view="primary" align="right" size="xs">Центр затрат</Text>
                    </div>
                </GridItem>
            </Grid>
            <div style={{marginTop:"20px"}}>
                <div style={{display:"flex", flexDirection:"row", marginBottom:"15px", alignItems:"center"}}>
                    <Text view="primary" style={{marginRight:"25px"}}>Номер билета: 555-1234567890</Text>
                    <Button view="primary" size="xs" label="Скачать" style={{marginRight:"10px"}}/>
                    <Button view="primary" size="xs" label="Отправить на почту"/>
                </div>
                <div style={{display:"flex", flexDirection:"row", marginBottom:"10px"}}>
                    <Text view="primary" style={{marginRight:"15px"}}>Пользовательские аналитики:</Text>
                    <Text view="normal" style={{marginRight:"10px"}}>Значение1</Text>
                    <Text view="normal" style={{marginRight:"10px"}}>Значение2</Text>
                </div>
                <div style={{display:"flex", flexDirection:"row", marginBottom:"10px"}}>
                    <Text view="primary" style={{marginRight:"15px"}}>Метки:</Text>
                    <Text view="normal" style={{marginRight:"10px"}}>Значение1</Text>
                    <Text view="normal" style={{marginRight:"10px"}}>Значение2</Text>
                </div>
            </div>
        </div>
    );
};

export default AviaItemDetails;