import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";
import React from "react";

const Class = ({aviaClass}) => {
    //внутрь передавать AirlineRules.Class
    if (aviaClass.IsEnabled) {
        return (
            <div>
                <Text size="s">{dictionary.classValue[aviaClass.Value]}</Text>
                <hr/>
            </div>
        )
    }
}
export default Class