import React, {useEffect, useState} from 'react';
import {Collapse} from "@consta/uikit/Collapse";
import {Text} from "@consta/uikit/Text";
import ApprovalStep from "./ApprovalStep";
import {useApiGET} from "../../../../utils/useAPI";

const ApprovalScheme = ({approvalScheme, approvers}) => {
    const [isOpen, setOpen] = useState(false);

    if (approvalScheme && approvers) {
        return (
            <div>
                <Collapse label={"Схема согласования"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)} hoverEffect rightSide={<Text view="secondary" size="s">{approvalScheme.Name}</Text>}>
                    <ApprovalStep steps={approvalScheme.PreSteps} label="Предкомандировочкая заявка" approvers={approvers}/>
                    <ApprovalStep steps={approvalScheme.Steps} label="Фактические расходы" approvers={approvers}/>
                </Collapse>
            </div>
        );
    }
};

export default ApprovalScheme;