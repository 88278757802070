import React, {useEffect, useState} from 'react';
import {TextField} from "@consta/uikit/TextField";
import {Modal} from "@consta/uikit/Modal";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import "./AuthModal.css"
import {Text} from "@consta/uikit/Text";
import {Select} from "@consta/uikit/Select";



const ClsModal = ({isModalOpen,setIsModalOpen}) => {

    const [login, setLogin] = useState("");
    const items =  [
        {
            label: '10',
            id: 1,
        },
        {
            label: '20',
            id: 2,
        },
        {
            label: '30',
            id: 3,
        },
        {
            label: "60",
            id: 4,
        }
    ]
    const [selectValue, setSelectValue] = useState(items[1])

    return (
        <Modal
            isOpen={isModalOpen}
            onClickOutside={() => setIsModalOpen(false)}

        >
            <Text view="primary" className="Title">
                Клиентская сессия (НЕ РАБОТАЕТ)
            </Text>
            <div style={{padding:"15px"}}>
                <Grid cols="1" gap="s">
                    <GridItem>
                        <TextField

                            placeholder="Логин"
                            value={login}
                            onChange={(e)=>setLogin(e.value)}
                        />
                    </GridItem>
                </Grid>
            </div>

            <div className="Action">
                <div style={{marginRight:"15px", width:"25%"}}>
                    <Select
                        size="s"
                        items={items}
                        value={selectValue}
                        onChange={({ value }) => setSelectValue(value)}
                    />
                </div>

                <Button label="Войти" size="s"  onClick={()=>{
                    console.log("Ta-Daaa")}}/>

            </div>
        </Modal>
    );
};

export default ClsModal;