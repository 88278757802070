import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const SendVouchers = ({notifs}) => {

    const GetSendRight = () => {
        if (notifs.includes("Vouchers")) {
            if (notifs.includes("Personal")) {
                //по приобретенным
                return <Text as="span" view="warning" weight="bold">{ruleDictionary.Notifs.Personal}</Text>
            } return <Text as="span" view="success" weight="bold">{ruleDictionary.Notifs.Account}</Text>
        } return <Text as="span" view="alert" weight="bold">{ruleDictionary.Notifs.None}</Text>
    }
    return (
        <div>
            <Text view="primary">Отправлять ваучеры: <GetSendRight/> </Text>
        </div>
    );
};

export default SendVouchers;