import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const CreateExpenseReports = ({account, createER}) => {

    const AllOrPersonal = () => {
        if (account !== "Unavailable") {
            return <Text view="success" as="span" weight="bold">{ruleDictionary.BuyTrip.Account}</Text>
        } return <Text view="warning" as="span" weight="bold">{ruleDictionary.BuyTrip.Personal}</Text>
    }
    const GetCreateRight = () => {
        return <Text as="span" view="linkMinor"> {ruleDictionary.CreateExpenseReports[createER]}</Text>
    }
    return (
        <div>
            <Text view="primary">Создание АО: <AllOrPersonal/> <GetCreateRight/></Text>
        </div>
    );
};

export default CreateExpenseReports;