import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import ViewTrips from "./Components/ViewTrips";
import BuyTrip from "./Components/BuyTrip";
import CreateExpenseReports from "./Components/CreateExpenseReports";
import Finance from "./Components/Finance";
import TripApprove from "./Components/TripApprove";
import EditEmployee from "./Components/EditEmployee";
import ApproveExpenseReports from "./Components/ApproveExpenseReports";
import EditTravelPolicies from "./Components/EditTravelPolicies";
import ViewExpenseReports from "./Components/ViewExpenseReports";
import EditRights from "./Components/EditRights";
import SendDocuments from "./Components/SendDocuments";
import SendVouchers from "./Components/SendVouchers";
import SendInvoices from "./Components/SendInvoices";

const RulesList = ({rules}) => {
    return (
        <div>
            <Grid cols="1">
                <GridItem>
                    <ViewTrips viewTrips={rules.ViewTrips}/>
                </GridItem>
                <GridItem>
                    <BuyTrip account={rules.BuyTripAccount} personal={rules.BuyTripPersonal}/>
                </GridItem>
                <GridItem>
                    <CreateExpenseReports createER={rules.CreateExpenseReports} account={rules.BuyTripAccount}/>
                </GridItem>
                <GridItem>
                    <Finance fin={rules.Finance}/>
                </GridItem>
                <GridItem>
                    <TripApprove approve={rules.Approve}/>
                </GridItem>
                <GridItem>
                    <EditEmployee editRight={rules.EditEmployee}/>
                </GridItem>
                <GridItem>
                    <ApproveExpenseReports approveER={rules.ApproveExpenseReports}/>
                </GridItem>
                <GridItem>
                    <EditTravelPolicies editTP={rules.EditTravelPolicies}/>
                </GridItem>
                <GridItem>
                    <ViewExpenseReports viewER={rules.ViewExpenseReports}/>
                </GridItem>
                <GridItem>
                    <EditRights editRs={rules.EditRights}/>
                </GridItem>
                <GridItem>
                    <SendDocuments docs={rules.SendDocuments}/>
                </GridItem>
                <GridItem>
                    <SendVouchers notifs={rules.Notifications}/>
                </GridItem>
                <GridItem>
                    <SendInvoices notifs={rules.Notifications}/>
                </GridItem>
            </Grid>
        </div>
    );
};

export default RulesList;