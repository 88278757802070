import React from 'react';
import {Text} from "@consta/uikit/Text";
import HotelRoutes from "./HotelRoutes";

const HotelDirections = ({directions}) => {
    //внуть передавать HotelRules.Directions
    if (directions.IsEnabled) {
        if (directions.Type === 1){
            return (
                <div>
                    <Text size="s">Только по Росии </Text>
                    <hr/>
                </div>
            )
        } else {
            return (
                <div>
                    <Text weight="semibold" size="s">Только по городам: </Text>
                    <HotelRoutes comp={directions}/>
                    <hr/>
                </div>
            )


        }
    }
};

export default HotelDirections;