import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const ViewExpenseReports = ({viewER}) => {
    const color = {
        All:"success",
        Personal:"warning"
    }
    return (
        <div>
            <Text view="primary">Просмотр АО: <Text as="span" view={color[viewER]} weight="bold">{ruleDictionary.ViewTrips[viewER]}</Text></Text>
        </div>
    );
};

export default ViewExpenseReports;