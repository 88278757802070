import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";
import React from "react";

const NotGreaterPrice = ({price}) => {
    //внуть передавать AirlineRules.NotGreaterPrice
    if (price.IsEnabled) {
        return (
            <div>
                <Text size="s">Дороже самых дешевых билетов не более, чем на {price.Value} {dictionary.valueType[price.ValueType]}</Text>
                <hr/>
            </div>
        )
    }
}
export default NotGreaterPrice