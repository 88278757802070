import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const SendDocuments = ({docs}) => {

    if (docs) {
        docs = "Yes"
    } else {
        docs = "No"
    }
    const color = {
        Yes: "success",
        No: "alert"
    }
    return (
        <div>
            <Text view="primary">Отправлять закрывающие документы: <Text view={color[docs]} weight="bold" as="span">{ruleDictionary.YesOrNo[docs]}</Text></Text>
        </div>
    );
};

export default SendDocuments;