import {Text} from "@consta/uikit/Text";
import React from "react";

const DayOff = ({dayOff, text}) => {
    // внутрь передать AirlineRules.DayOff
    if (dayOff) {
        return (
            <div>
                <Text size="s">{text}</Text>
                <hr/>
            </div>
        )
    }
}
export default DayOff