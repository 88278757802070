import React from 'react';
import {Text} from "@consta/uikit/Text";

const Routes = ({comp}) => {
    const routes = comp.Routes.map((route)=>{
        return (
            <div key={comp.Routes.indexOf(route)}>
                <Text size="s">{route.From.Name} ⇄ {route.To.Name}</Text>
            </div>
        )
    })
    return (
        <div>
            {routes}
        </div>
    );
};

export default Routes;