import React from 'react';

const useSandbox = () => {
    
    const getSandboxStatus = () => {
        let sandboxStatus = window.sessionStorage.getItem("isSandbox")
        if (!sandboxStatus || sandboxStatus==="false") return false
        if (sandboxStatus==="true") return true
    }

    const setSandboxStatus = (status) => {
        if (status===true) {
            window.sessionStorage.setItem("isSandbox", "true")
        } else {
            window.sessionStorage.setItem("isSandbox", "false")
        }
    }
    return {getSandboxStatus, setSandboxStatus}
};

export default useSandbox;