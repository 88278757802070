import React, {useState} from 'react';
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";
import {Text} from "@consta/uikit/Text";

const BuyTrip = ({personal, account}) => {


    const GetBuyTrip = () => {

        if (account !=="Unavailable") {
            return (
                <span>
                    <Text view="success" weight="bold" as="span">{ruleDictionary.BuyTrip.Account}</Text> <Text as="span" view="linkMinor"> {ruleDictionary.BuyTripAccount[account]}</Text>
                </span>
            )
        } else if (personal !=="Unavailable") {

            return (
                <span>
                    <Text view="warning" weight="bold" as="span">{ruleDictionary.BuyTrip.Personal}</Text> <Text as="span" view="linkMinor"> {ruleDictionary.BuyTripPersonal[personal]}</Text>
                </span>
            )

        } else {
            return <Text view="alert" as="span">Недоступно</Text>
        }
    }
    return (
        <div>
            <Text view="primary">Покупка поездок: <GetBuyTrip/></Text>
        </div>
    );
};

export default BuyTrip;