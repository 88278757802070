import React, {useState} from 'react';
import {Collapse} from "@consta/uikit/Collapse";
import {Text} from "@consta/uikit/Text";
import Routes from "../AviaTP/Components/Routes";
import AviaExceptRuleList from "../AviaTP/AviaExceptRuleList";
import {Card} from "@consta/uikit/Card";
import HotelRules from "./HotelRules";
import HotelRoutes from "./Components/HotelRoutes";
import HotelExceptRuleList from "./HotelExceptRuleList";

const HotelExceptions = ({Exceptions}) => {

    //внутрь передаем HotelRule.Exceptions

    const exceptionItem = Exceptions.map((ex)=>{
        if (ex.Type===2) {
            const [isOpen, setOpen] = useState(false);

            return (
                <div key={Exceptions.indexOf(ex)}>
                    <Collapse
                        label={<><Text>Для направлений по странам:</Text><HotelRoutes comp={ex}/></>}
                        isOpen={isOpen}
                        onClick={()=>setOpen(!isOpen)}
                        hoverEffect
                        form="default"
                    >
                        <HotelExceptRuleList ex={ex}/>
                    </Collapse>
                </div>
            )
        }
        const [isOpen, setOpen] = useState(false);
        return (
            <div key={Exceptions.indexOf(ex)}>
                <Collapse
                    label={<><Text>Для направлений по городам:</Text><HotelRoutes comp={ex}/></>}
                    isOpen={isOpen}
                    onClick={()=>setOpen(!isOpen)}
                    hoverEffect
                    form="default"
                >
                    <HotelExceptRuleList ex={ex}/>
                </Collapse>
            </div>
        )
    })
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l" style={{marginBottom:"10px"}}>
                    Исключения
                </Text>
                <div>
                    {exceptionItem}
                </div>
            </Card>
        </div>
    );
};

export default HotelExceptions;