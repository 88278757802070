import React from 'react';
import {Card} from "@consta/uikit/Card";
import {Grid, GridItem} from "@consta/uikit/Grid";
import DayOff from "../AviaTP/Components/DayOff";
import MaxPrice from "../AviaTP/Components/MaxPrice";
import TransferClass from "./Components/TransferClass";

const TransferRules = ({TransferRule}) => {
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Grid cols="1">
                    <GridItem>
                        <MaxPrice maxPrice={TransferRule.MaxPrice}/>
                    </GridItem>
                    <GridItem>
                        <TransferClass Class={TransferRule.Class}/>
                    </GridItem>
                    <GridItem>
                        <DayOff dayOff={TransferRule.DayOff} text="Только в будние дни"/>
                    </GridItem>
                </Grid>
            </Card>
        </div>
    );
};

export default TransferRules;