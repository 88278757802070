import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Grid, GridItem} from "@consta/uikit/Grid";

import {Card} from "@consta/uikit/Card";
import MaxPrice from "../AviaTP/Components/MaxPrice";
import NotGreaterPrice from "../AviaTP/Components/NotGreaterPrice";
import DayOff from "../AviaTP/Components/DayOff";
import Class from "../AviaTP/Components/Class";
import Directions from "../AviaTP/Components/Directions";
import SapsanClass from "./Components/SapsanClass";
import TrainClass from "./Components/TrainClass";

const TrainRules = ({TrainRules}) => {
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l">Разрешено</Text>
                <Grid cols="1">
                    <GridItem>
                        <DayOff dayOff={TrainRules.DayOff} text="Только на поезда, проходящие в будние дни"/>
                    </GridItem>
                    <GridItem>
                        <MaxPrice maxPrice={TrainRules.MaxPrice}/>
                    </GridItem>
                    <GridItem>
                        <NotGreaterPrice price={TrainRules.NotGreaterPrice}/>
                    </GridItem>
                    <GridItem>
                        <Directions directions={TrainRules.Directions}/>
                    </GridItem>
                    <GridItem>
                        <TrainClass trainClass={TrainRules.Class}/>
                    </GridItem>
                    <GridItem>
                        <SapsanClass Class={TrainRules.SapsanClass}/>
                    </GridItem>
                </Grid>
            </Card>
        </div>
    );
};

export default TrainRules;