import {Text} from "@consta/uikit/Text";
import React from "react";
import Routes from "./Routes";

const Directions = ({directions}) => {
    //внуть передавать AirlineRules.Directions
    if (directions.IsEnabled) {
        if (directions.Type === 1){
            return (
                <div>
                    <Text size="s">Только по Росcии </Text>
                    <hr/>
                </div>
            )
        } else {
            return (
                <div>
                    <Text weight="semibold" size="s">Только по направлениям: </Text>
                    <Routes comp={directions}/>
                    <hr/>
                </div>
            )


        }
    }
}
export default Directions