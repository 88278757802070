import React, {useEffect, useState} from 'react';
import {TextField} from "@consta/uikit/TextField";
import {Modal} from "@consta/uikit/Modal";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import "./AuthModal.css"
import {Text} from "@consta/uikit/Text";
import useAuth from "../../utils/useAuth";
import AuthStatus from "./AuthStatus";
import {Checkbox} from "@consta/uikit/Checkbox";
import useSandbox from "../../utils/useSandbox";

const AuthModal = ({isModalOpen,setIsModalOpen}) => {

    const {getSandboxStatus, setSandboxStatus} = useSandbox()

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [loginFieldStatus, setLoginFieldStatus] = useState("")
    const [isSandbox, setIsSandbox] = useState(getSandboxStatus())

    const {tokenStatus, email, ping, generateToken} = useAuth()

    useEffect(()=>{
        ping()
    }, [])

    const handleLoginClick = () => {
        // проверка на корректную почту
        const validLogin = login.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        if (validLogin) {
            generateToken(validLogin[0], password)
        } else {
            setLoginFieldStatus("alert")
            setTimeout(()=>setLoginFieldStatus(""), 1000)
        }

    }
    return (
        <Modal
            isOpen={isModalOpen}
            onClickOutside={() => setIsModalOpen(false)}
            style={{width:"25%"}}
        >
            <div >
                <div className="Title">
                    <Text view="primary" size="l">
                        Авторизация в BackOffice
                    </Text>
                </div>
                <form>
                    <div style={{padding:"15px"}}>
                        <Grid cols="1" gap="s">
                            <GridItem>
                                <TextField
                                    status={loginFieldStatus}
                                    label="Логин"
                                    size="l"
                                    type="email"
                                    value={login}
                                    onChange={(e)=>setLogin(e)}
                                />
                            </GridItem>
                            <GridItem>
                                <TextField
                                    label="Пароль"
                                    size="l"
                                    type="password"
                                    value={password}
                                    onChange={(e)=>{setPassword(e)}}
                                />
                            </GridItem>
                        </Grid>
                        <Checkbox
                            label="Sandbox"
                            checked={isSandbox}
                            onChange={()=>{
                                setIsSandbox(!isSandbox)
                                setSandboxStatus(!isSandbox)
                            }}
                            style={{marginTop:"15px"}} />
                        </div>

                    <div className="Action">
                        <AuthStatus tokenStatus={tokenStatus}/>
                        <Button label="Войти" size="m"  onClick={handleLoginClick}/>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AuthModal;