import {Text} from "@consta/uikit/Text";
import React from "react";

const IncludedBaggage = ({IncludedBaggage}) => {
    //внутрь передавать AirlineRules.Tariff.IncludedBaggage
    if (IncludedBaggage) {
        return (
            <div>
                <Text size="s">Только с багажом</Text>
                <hr/>
            </div>
        )
    }
}
export default IncludedBaggage