import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import MaxPrice from "./Components/MaxPrice";
import Vat from "./Components/Vat";
import Refundable from "../AviaTP/Components/Refundable";
import DayOff from "../AviaTP/Components/DayOff";

const HotelExceptRuleList = ({ex}) => {
    return (
        <div>
            <Grid cols="1">
                <GridItem>
                    <MaxPrice maxPrice={ex.MaxPrice}/>
                </GridItem>
                <GridItem>
                    <Vat vat={ex.Tariff.Vat}/>
                </GridItem>
                <GridItem>
                    <Refundable refundable={ex.Tariff.Refundable}/>
                </GridItem>
                <DayOff dayOff={ex.DayOff} text="Только в будние дни"/>
            </Grid>
        </div>
    );
};

export default HotelExceptRuleList;