import React from "react";
import "./styles.css";
import SearchPage from "./pages/SearchPage/SearchPage";
import { createBrowserRouter, Navigate,  RouterProvider} from "react-router-dom";
import {Theme} from "@consta/uikit/Theme";
import TemplatePage from "./pages/TemplatePage/TemplatePage";
import CompanyPage from "./pages/CompanyPage/CompanyPage";
import EmployeePage from "./pages/EmployeePage/EmployeePage";
import TripPage from "./pages/TripPage/TripPage";
import CartPage from "./pages/CartPage/CartPage";
import ErrorPage from "./pages/ErrorPage";

import NotFoundPage from "./pages/NotFoundPage";
import useTheme from "./utils/useTheme";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";


function App() {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: Infinity,
                cacheTime: Infinity,
                refetchOnReconnect: false,
                retry: false
            },
        },
    });


    const {items, getItemIcon, value, setValue, getTheme} = useTheme()


    const router = createBrowserRouter([
        {
            path: "/",
            element: <TemplatePage themeItems={items} getItemIcon={getItemIcon} setThemeValue={setValue} themeValue={value}/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "/SearchPage",
                    element: <SearchPage/>,
                },
                {
                    path: "/EmployeePage/:id",
                    element: <EmployeePage/>,
                },
                {
                    path: "/CompanyPage/:id",
                    element: <CompanyPage/>,
                },
                {
                    path: "/TripPage/:id",
                    element: <TripPage/>,
                },
                {
                    path: "/CartPage/:id",
                    element:<CartPage/> ,
                },
                {
                    path: "",
                    element: <Navigate to="/SearchPage"/>,
                },
                {
                    path: "*",
                    element: <NotFoundPage/>
                },
            ]
        }

    ]);

    return (
        <QueryClientProvider client={queryClient}>
            <Theme className="App" preset={getTheme(value)}>
                <RouterProvider router={router} />
            </Theme>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
  );
}

export default App;
