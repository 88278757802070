import React, {useState} from 'react';
import {v4 as uuidv4} from "uuid";
import SearchPage from "../pages/SearchPage/SearchPage";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {IconHome} from "@consta/icons/IconHome";
import {useQueryClient} from "react-query";





 const useTabs = () => {

     const QueryClient = useQueryClient()

     const getPageType = (tab) => {
         const a = tab.URL.split("/")
         switch (a[1]) {
             case "EmployeePage":
                 return ["employee", a[2]]

             case "CompanyPage":
                 return ["company", a[2]]

             case "TripPage":
                 return ["trip", a[2]]

             case "CartPage":
                 return ["cart", a[2]]

             case "SearchPage":
                 return ("search")

         }
     }


     const [tabs, setTabs] = useState([
         {
             key: uuidv4(),
             label: "Главная страница",
             leftIcon: IconHome,
             fixed: true,
             content: <SearchPage/>,
             URL:"/SearchPage"
         },
         {
             key: uuidv4(),
             label: "Страница",
             fixed: false,
             content: <Outlet/>,
             URL: window.location.pathname
         }
     ])

    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState(tabs[1])

    const changeTab = (tab) => {
        setActiveTab(tab)
        navigate(tab.URL)
    }

    const HandleChangeTab = (tab) =>{
        if (tab === activeTab) {
            QueryClient.refetchQueries([...getPageType(tab)], {active: true})
        } else {
            changeTab(tab)
        }


    }

    const createTab = (label, url) => {
        setTabs([...tabs, {
            key: uuidv4(),
            label: label,
            fixed: false,
            content: <Outlet/>,
            URL: url
        }])
    }

    const addTabWithButton = () => {
         try {
             const a = new URL(prompt("Открыть ссылку"))
             createTab("Ссылка", a?.pathname)
         } catch (e) {
             console.log("Ошибка в ссылке", e)
         }

    }
    const removeTab = (tab, e) => {

        e.e.stopPropagation()
        const previousTabIndex = tabs.indexOf(tab)-1
        setTabs(tabs.filter((el) => el.key !== tab.key))
        changeTab(tabs[previousTabIndex])
        QueryClient.removeQueries([...getPageType(tab)])
    }
    

    return {tabs, createTab, removeTab, activeTab, HandleChangeTab, addTabWithButton}
};

export default useTabs;
