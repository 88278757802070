import axios from "axios";
import useSandbox from "./useSandbox";



const MY_ENV='https://back.arcane-server.ru/bo/?api='

const {getSandboxStatus} = useSandbox()


const useApiGET = (url) => {
    const isSandbox = getSandboxStatus()
    const bearer = window.localStorage.getItem(`access${isSandbox ? "-sandbox" : ""}`)

    return axios.get(`${MY_ENV}${url}`, {headers:{
            'Authorization': `Bearer ${bearer}`
        }})

};

const useApiPOST = (url, data, withToken=true) => {
    const isSandbox = getSandboxStatus()
    const bearer = window.localStorage.getItem(`access${isSandbox ? "-sandbox" : ""}`)

    const config = withToken ? {headers:{'Authorization': `Bearer ${bearer}`}} : null

    return axios.post(`${MY_ENV}${url}`, data, config)
}

export {useApiGET, useApiPOST}