import React from 'react';
import {Text} from "@consta/uikit/Text";

const Vat = ({vat}) => {
    //внутрь передавать HotelRules.Tariff.Vat
    if (vat) {
        return (
            <div>
                <Text size="s">Только с НДС</Text>
                <hr/>
            </div>
        )
    }
};

export default Vat;