import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const ApproveExpenseReports = ({approveER}) => {
    const color = {
        Available: "success",
        Unavailable: "alert"
    }
    return (
        <div>
            <Text view="primary">Согласование АО: <Text view={color[approveER]} weight="bold" as="span">{ruleDictionary.Approve[approveER]}</Text></Text>
        </div>
    );
};

export default ApproveExpenseReports;