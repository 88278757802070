const tripDictionary = {

    tripStatus: {
        "Cancelled": "Отменена",
        "Completed": "Завершена",
        "Booked": "Оформлена",
        "Canceled": "Отменена",
        "Created": "Оформлена",
    }


}
export default tripDictionary