import React from 'react';
import {Card} from "@consta/uikit/Card";
import {Collapse} from "@consta/uikit/Collapse";
import { IconClose } from '@consta/icons/IconClose';


const VIPHallTPMain = ({VipHallRule}) => {

    if (VipHallRule.Apply && VipHallRule.AllowBook) {
        return (
            <Card border={true} style={{width:"99%", paddingLeft:"5px"}}>
                <Collapse label={"VIP-залы: ограничений нет"} isOpen={false} icon={IconClose}>
                </Collapse>
            </Card>
        );
    } else {
        return (
            <Card border={true} style={{width:"99%", paddingLeft:"5px"}}>
                <Collapse label={"VIP-залы: покупка запрешена"} isOpen={false}>
                </Collapse>
            </Card>
        )
    }
};

export default VIPHallTPMain;