import React from 'react';
import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";

const MaxPrice = ({maxPrice}) => {
    // передавать HotelRules.MaxPrice

    const Eclc = () =>{
        if (maxPrice.IsEclcNotIncluded) {
            return "без учета раннего заезда и позднего выезда"
        }
        return "с учетом раннего заезда или позднего выезда"
    }
    if (maxPrice.IsEnabled) {
        return (
            <div>
                <Text size="s">не дороже {maxPrice.Value} {dictionary.currency[maxPrice.Currency]} за ночь {<Eclc/>}</Text>
                <hr/>
            </div>
        )
    }
};

export default MaxPrice;