import React from 'react';
import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";

const TrainClass = ({trainClass}) => {
    if (trainClass.IsEnabled) {
        return (
            <div>
                <Text size="s">{dictionary.trainClass[trainClass.Value]}</Text>
                <hr/>
            </div>
        )
    }
};

export default TrainClass;