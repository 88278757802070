import React from 'react';
import {Card} from "@consta/uikit/Card";

import {Grid, GridItem} from "@consta/uikit/Grid";

import TaxiClass from "./Components/TaxiClass";
import DayOff from "../AviaTP/Components/DayOff";

const TaxiRules = ({TaxiRule}) => {

    // запрет на покупку сделал через DayOff
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Grid cols="1">
                    <GridItem>
                        <TaxiClass Class={TaxiRule.Class}/>
                    </GridItem>
                    <GridItem>
                        <DayOff dayOff={TaxiRule.DayOff} text="Только в будние дни"/>
                    </GridItem>
                    <GridItem>
                        <DayOff dayOff={TaxiRule.prohibitBook} text="Покупка такси запрещена"/>
                    </GridItem>
                </Grid>
            </Card>
        </div>
    );
};

export default TaxiRules;