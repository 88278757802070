import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const EditTravelPolicies = ({editTP}) => {

    if (editTP) {
        editTP = "Available"
    }else {
        editTP = "Unavailable"
    }
    const color = {
        Available: "success",
        Unavailable: "alert"
    }
    return (
        <div>
            <Text view="primary">Создавать и редактировать Travel-политики: <Text view={color[editTP]} weight="bold" as="span">{ruleDictionary.Approve[editTP]}</Text></Text>
        </div>
    );
};

export default EditTravelPolicies;