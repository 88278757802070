import React from 'react';
import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";

const TaxiClass = ({Class}) => {

    if (Class.IsEnabled) {
        return (
            <div>
                <Text size="s">Только "{dictionary.taxiClass[Class.Value]}" класс или ниже </Text>
                <hr/>
            </div>
        );
    }

};

export default TaxiClass;