import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import MaxPrice from "../AviaTP/Components/MaxPrice";
import NotGreaterPrice from "../AviaTP/Components/NotGreaterPrice";
import DayOff from "../AviaTP/Components/DayOff";
import Class from "../AviaTP/Components/Class";
import SapsanClass from "./Components/SapsanClass";
import TrainClass from "./Components/TrainClass";

const TrainExceptRuleList = ({ex}) => {
    return (
        <div>
            <Grid cols="1">
                <GridItem>
                    <MaxPrice maxPrice={ex.MaxPrice}/>
                </GridItem>
                <GridItem>
                    <NotGreaterPrice price={ex.NotGreaterPrice}/>
                </GridItem>
                <GridItem>
                    <TrainClass trainClass={ex.Class}/>
                </GridItem>
                <GridItem>
                    <SapsanClass Class={ex.SapsanClass}/>
                </GridItem>
                <GridItem>
                    <DayOff dayOff={ex.DayOff} text="Только на поезда, проходящие в будние дни"/>
                </GridItem>
            </Grid>
        </div>
    );
};

export default TrainExceptRuleList;