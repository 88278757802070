import React, {useState} from 'react';
import {Card} from "@consta/uikit/Card";
import {Collapse} from "@consta/uikit/Collapse";
import TaxiRules from "./TaxiRules";


const TaxiTPMain = ({TaxiVoucherRule}) => {
    const [isOpen, setOpen] = useState(false);

    if (TaxiVoucherRule.Apply) {
        return (
            <Card border={true} style={{width:"99%", paddingLeft:"5px"}}>
                <Collapse label={"Такси"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)}>
                    <TaxiRules TaxiRule={TaxiVoucherRule}/>
                </Collapse>
            </Card>
        );
    }
};

export default TaxiTPMain;