import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const EditRights = ({editRs}) => {
    const color = {
        Available: "success",
        Unavailable: "alert"
    }
    return (
        <div>
            <Text view="primary">Предоставлять доступ: <Text view={color[editRs]} weight="bold" as="span">{ruleDictionary.EditRights[editRs]}</Text></Text>
        </div>
    );
};

export default EditRights;