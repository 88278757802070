import React, {useContext} from 'react';
import {Text} from "@consta/uikit/Text";
import RuleDictionary from "../../../../utils/Dictionaries/RuleDictionary";
import {Card} from "@consta/uikit/Card";
import { v4 as uuidv4 } from 'uuid';
import hoveredClasses from "../../../../CardWithHover.module.css"
import {TabContext} from "../../../../utils/TabContext";


const ExpenseReportApprovalStep = ({steps, label, approvers}) => {

    const createTab = useContext(TabContext)


    const GetRoles = ({roles}) => {
        // рук отдела - 1
        // рук цз - 2
        return roles.map((role)=>{
            return (
                <Card border={true} style={{padding:"5px", marginBottom:"5px", borderColor:"var(--color-bg-alert)"}} key={uuidv4()}>
                    <Text view='primary' style={{marginRight:"8px"}}> {RuleDictionary.Roles[role]} </Text>
                </Card>
            )
        })
    }

    const GetApprovers = ({apprs}) => {
        return apprs.map(appr =>{
            for (let i = 0; i < approvers.length; i++) {
                if (appr === approvers[i].id) {
                    return (
                        <Card
                            onClick={()=>createTab(`👤 - ${approvers[i].employeeId}`, `/EmployeePage/${approvers[i].employeeId}`)}
                            className={hoveredClasses.Item}
                            key={uuidv4()}
                            border={true}
                            style={{padding:"5px", marginBottom:"5px", borderColor:"var(--color-bg-brand)"}}
                        >
                            <Text view="primary" style={{marginRight:"8px"}}> {approvers[i].registrationName} </Text>
                        </Card>
                        )
                }
            }
        })
    }

    const preSteps = steps.map(step =>{

        return (
            <div key={uuidv4()} style={{marginBottom:"15px"}}>
                <Card>
                    <Text view="primary" style={{marginBottom:"5px", marginLeft:"5px"}}>{steps.indexOf(step)+1}. Шаг</Text>
                    <div style={{padding:"5px", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div style={{ display:"flex", flexDirection:"column", width:"45%", marginLeft:"5px", marginRight:"20px"}}>
                            <GetRoles roles={step.Roles}/>
                            <GetApprovers apprs={step.Approvers}/>
                        </div>
                        <Text view="secondary" style={{width:"55%"}}>{(step.Approvers.length + step.Roles.length>1) ?  RuleDictionary.Conditions[step.ConditionOfApproval] : ""}</Text>
                    </div>
                </Card>

            </div>
        )
    })

    return (
        <div>
            <Card style={{padding:"15px 15px 10px 15px", marginBottom:"45px"}} border={true}>
                <Text size="s" style={{paddingBottom:"20px"}}>{label}</Text>
                {preSteps}
            </Card>
        </div>
    );
};

export default ExpenseReportApprovalStep;