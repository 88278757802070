import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";

const AviaDirectionItem = () => {
    return (

            <Card style={{height:"20%", width:"100%", marginBottom:"15px", padding:"5px 10px 5px 10px"}}>
                <Grid cols={3}>
                    <GridItem>
                        <div>
                            <Text view="primary" align="left" size="l">Ростов-на-Дону</Text>
                            <Text view="secondary" align="left" size="xs">Дата-время вылета</Text>
                        </div>
                    </GridItem>
                    <GridItem>
                        <div>
                            <Text view="primary" align="center" size="s">Перевозчик</Text>
                            <Text view="primary" align="center" size="s">Длительность</Text>
                            <Text view="primary" align="center" decoration="underline" cursor="pointer" size="s">Доп. условия</Text>
                        </div>
                    </GridItem>
                    <GridItem>
                        <div>
                            <Text view="primary" align="right" size="l">Комсомольск-на-Амуре</Text>
                            <Text view="secondary" align="right" size="xs">Дата-время прилета</Text>
                        </div>
                    </GridItem>
                </Grid>
            </Card>

    );
};

export default AviaDirectionItem;