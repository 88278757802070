import React from 'react';
import {ResponsesConnectionError} from "@consta/uikit/ResponsesConnectionError";
import {Button} from "@consta/uikit/Button";

const ErrorPage = () => {
    return (
        <div style={{width:"100vw", height:"100vh"}}>
            <ResponsesConnectionError title="Что-то пошло не так" actions={<Button label="Перезагрузить страницу" onClick={()=>{location.reload()}}/>}/>
        </div>
    );
};

export default ErrorPage;