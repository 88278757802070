import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";

const ForeignDoc = ({document}) => {
    const date = new Date (document.DueDate)
    return (
        <Card style={{padding:"10px", marginBottom:"10px"}}>
            <Grid cols="3">
                <GridItem>
                    <Text view="primary" size="l">{document.Number}</Text>
                    <Text size="xs" view="system">Иностранный паспорт</Text>
                </GridItem>
                <GridItem col="2">
                    <Text view="primary" size="l">{document.Surname} {document.Name} {document.Patronymic}</Text>
                    <Text size="xs" view="secondary">{date.getDate()}.{date.getMonth()}.{date.getFullYear()}</Text>
                </GridItem>
            </Grid>
        </Card>
    );
};

export default ForeignDoc;