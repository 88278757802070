import React from 'react';
import {Layout} from "@consta/uikit/Layout";
import classes from "../EmployeePage/EmployeePage.module.css";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Card} from "@consta/uikit/Card";
import EmployeeSearch from "../../Components/SearchComponents/Employees/EmployeeSearch";
import {Text} from "@consta/uikit/Text";

const SearchPage = () => {

    document.title = "altBack - Главная страница"

    return (
        <div className={classes.App}>
            <Grid cols="2" style={{height:"100%", width:"100%"}}>
                <GridItem>
                    <Layout direction="column">
                        <Layout fixed flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <EmployeeSearch/>
                            </Card>
                        </Layout>
                        <Layout fixed flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <Text size="2xl" view="primary">Компании</Text>
                            </Card>
                        </Layout>
                    </Layout>
                </GridItem>
                <GridItem>
                    <Layout direction="column">
                        <Layout fixed flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <Text size="2xl" view="primary">Поездки</Text>
                            </Card>
                        </Layout>
                        <Layout flex={1} className={classes.Layout1} direction="row">
                            <Card style={{height:"100%", width:"100%"}} border={true}>
                                <Text size="2xl" view="primary">Корзины и блокноты</Text>
                            </Card>
                        </Layout>
                    </Layout>
                </GridItem>
            </Grid>
        </div>
    );
};

export default SearchPage;