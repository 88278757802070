import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import DayOff from "./Components/DayOff";
import MaxPrice from "./Components/MaxPrice";
import NotGreaterPrice from "./Components/NotGreaterPrice";
import Directions from "./Components/Directions";
import Time from "./Components/Time";
import Class from "./Components/Class";
import Refundable from "./Components/Refundable";
import IncludedBaggage from "./Components/IncludedBaggage";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";

const AviaRules = ({AirlineRules}) => {
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l">Разрешено</Text>
                <Grid cols="1">
                    <GridItem>
                        <DayOff dayOff={AirlineRules.DayOff} text="Только с вылетом и прилетом в будние дни"/>
                    </GridItem>
                    <GridItem>
                        <MaxPrice maxPrice={AirlineRules.MaxPrice}/>
                    </GridItem>
                    <GridItem>
                        <NotGreaterPrice price={AirlineRules.NotGreaterPrice}/>
                    </GridItem>
                    <GridItem>
                        <Directions directions={AirlineRules.Directions}/>
                    </GridItem>
                    <GridItem>
                        <Time time={AirlineRules.Time}/>
                    </GridItem>
                    <GridItem>
                        <Class aviaClass={AirlineRules.Class}/>
                    </GridItem>
                    <GridItem>
                        <Refundable refundable={AirlineRules.Tariff.Refundable}/>
                    </GridItem>
                    <GridItem>
                        <IncludedBaggage IncludedBaggage={AirlineRules.Tariff.IncludedBaggage}/>
                    </GridItem>
                </Grid>
            </Card>
        </div>
    );
};

export default AviaRules;