import React, {useState} from 'react';
import {useApiPOST} from "./useAPI";
import {v4 as uuidv4} from "uuid";

const useSearch = (link, historySearchKey) => {

    // хук, в котором вся логика работы поиска. хук универсальный и подходит для любого типа поиска - сотрудники, компании, поездки, корзины/блокноты
    // принимаем аргументами ссылку на запрос для поиска и ключ для хранения истории в локал сторедж

    // из хука достаем: стейты (поле поиска, статус поля, результат поиска в виде массива) и методы (поиск, событие нажатия на энтер, кнопку поиска, кнопку перхода по айди)

    // также есть поддержка истории поиска. историю можно отображать как чипы с текстом запроса
    // по ним достаем: список с историей запросов и метод по удалению. добавление реализовано внутри хука

    const [searchResult, setSearchResult] = useState([])

    const historyItemsFromLS = JSON.parse(window.localStorage.getItem(`historySearch-${historySearchKey}`))
    const [historyItems, setHistoryItems] = useState(historyItemsFromLS ? historyItemsFromLS : [])

    const [value, setValue] = useState(null)
    const [fieldStatus, setFieldStatus] = useState("")

    const setHistoryGlobal = (newHistoryItems) => {
        window.localStorage.setItem(`historySearch-${historySearchKey}`, JSON.stringify(newHistoryItems))
        setHistoryItems(newHistoryItems)
    }
    const handleRemoveHistoryItem = (item, { e }) => {
        e.stopPropagation();
        let filteredHistoryItems = historyItems.filter((el)=>el.key !== item.key)
        setHistoryGlobal(filteredHistoryItems)
    }

    const addHistoryItem = (newItemValue) => {
        let newItems = [ {
            label:newItemValue,
            key: uuidv4()
        }, ...historyItems.slice(0, 4)]
        setHistoryGlobal(newItems)
    }

    const clearHistory = () => {
      setHistoryGlobal([])
    }

    const doSearch = () => {
        if (value) {
            setSearchResult("loading")
            addHistoryItem(value)
            useApiPOST(link, {"query": value})
                .then(r=>{
                    setSearchResult(r.data)

                })
                .catch(e=>{
                    setSearchResult("error")
                    console.log(e)
                })
        } else {
            setFieldStatus("alert")
            setTimeout(()=>setFieldStatus(""), 1000)
        }
    }

    const handeIdButtonClick = (createTab)=> {
        const validValue = value.match(/^\d+$/)
        if (validValue) {
            createTab(`👤 - ${validValue[0]}`, `/EmployeePage/${validValue[0]}`)
        } else {
            setFieldStatus("alert")
            setTimeout(()=>setFieldStatus(""), 1000)
        }
    }

    const handleEnterClick = (e) => {
        if (e.key === "Enter") {
            doSearch()
        }
    }

    return {fieldStatus, searchResult, value, setValue, doSearch, handeIdButtonClick, handleEnterClick, historyItems, handleRemoveHistoryItem, clearHistory}

};

export default useSearch;