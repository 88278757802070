import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";
import React from "react";

const MaxPrice = ({maxPrice}) => {
    //внуть передавать AirlineRules.MaxPrice
    if (maxPrice.IsEnabled) {
        return (
            <div>
                <Text size="s">Не дороже {maxPrice.Value} {dictionary.currency[maxPrice.Currency]} в один конец</Text>
                <hr/>
            </div>
        )
    }
}
export default MaxPrice