import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {Collapse} from "@consta/uikit/Collapse";
import {useApiGET} from "../../../../utils/useAPI";
import {v4 as uuidv4} from "uuid";
import {TabContext} from "../../../../utils/TabContext";

const ExpenseReportsApprovers = ({approvers}) => {
    const [isOpen, setOpen] = useState(false);

    const createTab = useContext(TabContext)


    const ApproversNames = approvers.map(approver =>{
        if (approver.EmployeeId !==0) {
            return (
                <GridItem key={uuidv4()}>
                    <Text view="primary" cursor="pointer" style={{marginBottom:"10px"}} onClick={()=>createTab(`👤 - ${approver.EmployeeId}`, `/EmployeePage/${approver.EmployeeId}`)}>{approver.Name}</Text>
                </GridItem>
            )
        } else return (
            <GridItem key={uuidv4()}>
                <Text view="primary" style={{marginBottom:"10px"}}>{approver.Email}</Text>
            </GridItem>
        )
    })

        return (
            <div>
                <Collapse label={"Согласующие АО"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)} hoverEffect>
                    {
                        approvers.length !==0 ?
                            <Grid cols="1">
                                {ApproversNames}
                            </Grid> :
                            <Text view="primary">Любое лицо</Text>
                    }
                </Collapse>
            </div>
        );
};

export default ExpenseReportsApprovers;