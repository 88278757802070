import ViewExpenseReports from "../../Components/EmpolyeeComponents/UserRules/Components/ViewExpenseReports";
import EditRights from "../../Components/EmpolyeeComponents/UserRules/Components/EditRights";

const ruleDictionary = {

    "ViewTrips":{
        All: "Все",
        Personal: "Только свои",
        Undefined: "Нет"
    },
    "BuyTrip":{
       Personal: "Для себя",
       Account: "Для всех сотрудников"
    },
    "BuyTripPersonal": {
        Unlimited:"без ограничений",
        OnlyAfterApprove: "по согласованию с авторизующим лицом",
        TravelPolicy: "в рамках travel-политики",
        ApprovalScheme:"по схеме согласования"
    },
    "BuyTripAccount": {
        Unlimited:"без ограничений",
        OnlyAfterApprove: "по согласованию с авторизующим лицом",
        TravelPolicy: "в рамках travel-политик сотрудников",
        ApprovalScheme:"по схеме согласования"
    },
    "CreateExpenseReports": {
        Unlimited: "без ограничений",
        OnlyAfterApprove: "по согласованию с авторизующим лицом",
        ApprovalScheme: "по схеме согласования",
    },
    "Finance": {
        Available: "Доступна",
        Unavailable: "Недоступна"
    },
    "Approve": {
        Available: "Доступно",
        Unavailable: "Недоступно"
    },
    "EditEmployee": {
        Personal: "Только о себе",
        All: "Обо всех"
    },
    "EditRights": {
        Available: "Может",
        Unavailable: "Не может"
    },
    "SendNotifications": {

    },
    "YesOrNo": {
        Yes: "Да",
        No: "Нет"
    },
    "Notifs":{
        Personal: "По приобретенным поездкам",
        Account: "По всем поездкам",
        None: "Нет"
    },
    "Conditions": [
        "Требуется согласие от всех участников.",
        "Требуется согласие от одного любого участника.",
        "Требуется согласие от всех выбранных участников"
    ],
    "ApprovalType": [
        "Согласование всех поездок",
        "Согласование поездок, нарушающих Travel политику"
    ],
    "Roles": [
        "",
        "Руководитель отдела",
        "Руководитель центра затрат"
    ],
    "UserStatus": [
        "",
        "Не завершил регистрацию",
        "Активный",
        "Отклоненный без возможности восстановления",
        "Отключенный с возможностью восстановления"
    ]

}
export default ruleDictionary