import React from 'react';

import AviaDirectionItem from "./AviaDirectionItem";

import {Card} from "@consta/uikit/Card";
import AviaItemDetails from "./AviaItemDetails";

const AviaItemCard = () => {
    return (
        <Card style={{height:"100%", width:"100%", padding:"15px 10px 15px 15px", overflowY:"scroll"}} border={true}>
            <AviaDirectionItem/>
            <AviaDirectionItem/>
            <AviaItemDetails/>
        </Card>
    );
};

export default AviaItemCard;