import React, {useEffect, useMemo, useState} from 'react';
import {Text} from "@consta/uikit/Text";
import { useParams} from "react-router-dom";

const CompanyPage = () => {
    const params = useParams()

    //const [companyID, setCompanyID] = useState('')


    // useMemo(()=>{
    //     console.log(`Грузим данные по компании... ${params.id}`)
    //     setCompanyID(params.id)
    // }, [params])

    return (
        <>
            <Text view="primary">
                Страница компании с id - {params.id}
            </Text>
        </>
    );
};

export default CompanyPage;