import React from 'react';
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";
import {Grid, GridItem} from "@consta/uikit/Grid";
import DayOff from "../AviaTP/Components/DayOff";
import Refundable from "../AviaTP/Components/Refundable";
import Vat from "./Components/Vat";
import HotelDirections from "./Components/HotelDirections";
import MaxPrice from "./Components/MaxPrice";

const HotelRules = ({HotelRules}) => {
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l">Разрешено</Text>
                <Grid cols="1">
                    <GridItem>
                        <DayOff dayOff={HotelRules.DayOff} text="Только в будние дни"/>
                    </GridItem>
                    <GridItem>
                        <MaxPrice maxPrice={HotelRules.MaxPrice}/>
                    </GridItem>
                    <GridItem>
                        <Refundable refundable={HotelRules.Tariff.Refundable}/>
                    </GridItem>
                    <GridItem>
                        <Vat vat={HotelRules.Tariff.Vat}/>
                    </GridItem>
                    <GridItem>
                        <HotelDirections directions={HotelRules.Directions}/>
                    </GridItem>

                </Grid>
            </Card>
        </div>
    );
};

export default HotelRules;