import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const TripApprove = ({approve}) => {
    const color = {
        Available: "success",
        Unavailable: "alert"
    }
    return (
        <div>
            <Text view="primary">Согласование поездок: <Text view={color[approve]} weight="bold" as="span">{ruleDictionary.Approve[approve]}</Text></Text>
        </div>
    );
};

export default TripApprove;