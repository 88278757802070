import React, {useRef, useState} from 'react';
import {Text} from "@consta/uikit/Text";
import {Grid, GridItem} from "@consta/uikit/Grid";
import EmployeeDictionary from "../../../utils/Dictionaries/EmployeeDictionary";
import DocumentBlock from "./DocumentBlock";
import {IconCheck} from "@consta/icons/IconCheck";
import {IconTrash} from "@consta/icons/IconTrash";
import {Button} from "@consta/uikit/Button";
import {IconAlert} from "@consta/icons/IconAlert";
import {Popover} from "@consta/uikit/Popover";
import {Card} from "@consta/uikit/Card";



const EmployeeMain = ({employee}) => {



    const anchorRef = useRef()
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const date = new Date (employee.Birthday)
    const GetEmployeeStatus = () => {
      switch (employee.Status) {
          case "User":
              return <IconCheck view="primary" style={{marginRight:"20px"}}/>
          case "Archived":
              return <IconTrash view="primary" style={{marginRight:"20px"}}/>
          case "Active":
              return
      }
    }
    const GetEmployeeExternalId = () => {
      if (employee.ExternalId) return (
          <div style={{marginRight:"20px"}}>
            <Button size="s" iconRight={IconAlert} onlyIcon view="clear" ref={anchorRef} onClick={()=>{setIsPopoverVisible(!isPopoverVisible)}}/>
              {isPopoverVisible && (
                  <Popover
                      direction="upCenter"
                      spareDirection="downStartLeft"
                      offset="2xs"
                      arrowOffset={0}
                      onClickOutside={()=>{setIsPopoverVisible(false)}}
                      isInteractive={true}
                      anchorRef={anchorRef}
                      equalAnchorWidth={false}
                  >
                      <div >
                          <Card style={{backgroundColor: "var(--color-bg-default)", padding:"5px"}}>
                              <Text view="primary" size="s">Сотрудника передавали по API/1C</Text>
                              <Text view="secondary" size="xs">{employee.ExternalId}</Text>
                          </Card>
                      </div>
                  </Popover>
              )}
          </div>
      )
    }
    return (
        <div style={{padding:"20px"}}>
            <div id="HeaderE" style={{paddingBottom:"20px"}}>
                <Text view="primary" size="xl" lineHeight="2xs">{employee.Surname} {employee.Name} {employee.Patronymic}</Text>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <Text size="s" style={{marginRight:"10px", paddingBottom:"8px"}} view="ghost" >{employee.Documents[0]?.LastName} {employee.Documents[0]?.FirstName} {employee.Documents[0]?.MiddleName}</Text>
                </div>
                <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                    <Text size="s" view="primary" style={{marginRight:"20px"}}>{date.toLocaleString("ru", {dateStyle:"short"})}</Text>
                    <Text size="s" view="primary" style={{marginRight:"20px"}}>{EmployeeDictionary.Sex[employee.Sex]}</Text>
                    <Text size="s" view="primary" style={{marginRight:"20px"}}>{employee.Code}</Text>
                    <GetEmployeeStatus/>
                    <GetEmployeeExternalId/>
                </div>

            </div>
                {employee.EmployeeNumber &&
                    (<div style={{paddingBottom:"20px", display:"flex", flexDirection:"row"}}>
                        <Text size="xs" view="secondary">Табельный номер - {employee.EmployeeNumber}</Text>
                    </div>)}
            <div id="MainE" style={{paddingBottom:"30px"}}>
                <Grid cols="6" colGap="s">
                    <GridItem col={2}>
                        <Text view="primary" size="s">{employee.MobilePhone}</Text>
                        <Text size="xs" view="secondary">Телефон</Text>
                    </GridItem>
                    <GridItem col={4}>
                        <Text view="primary" size="s">{employee.Email}</Text>
                        <Text size="xs" view="secondary">Почта</Text>
                    </GridItem>

                </Grid>
            </div>
            <div>
                <DocumentBlock emp={employee}/>
            </div>
        </div>
    );
};

export default EmployeeMain;