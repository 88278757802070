import React, {useEffect, useState} from 'react';
import {Text} from "@consta/uikit/Text";
import RulesList from "./RulesList";
import Approvers from "./Components/Approvers";
import ApprovalScheme from "./Components/ApprovalScheme";
import ExpenseReportsApprovalScheme from "./Components/ExpenseReportsApprovalScheme";
import {Loader} from "@consta/uikit/Loader";
import {Informer} from "@consta/uikit/Informer";
import ExpenseReportsApprovers from "./Components/ExpenseReportsApprovers";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import { IconLink } from '@consta/icons/IconLink';
import RuleDictionary from "../../../utils/Dictionaries/RuleDictionary";
import {useApiGET} from "../../../utils/useAPI";


const RulesMain = ({rules, user, approvers, erApprovers, approvalScheme, schemeApprovers, erApprovalScheme, erSchemeApprovers}) => {

    const copyLink = () => {
        let link = `https://promo.smartway.today/set-password?userId=${rules.UserId}&email=${rules.Login}`

        navigator.clipboard.writeText(link)
            .then(()=>console.log("Ссылка скопирована: ", link))
            .catch((e)=>console.log(e))
    }

    const GetScheme = () => {
        if (rules.BuyTripAccount ==="ApprovalScheme" || rules.BuyTripPersonal ==="ApprovalScheme") {
            return (
                <div style={{paddingBottom:"20px"}}>
                    <ApprovalScheme approvers={schemeApprovers} approvalScheme={approvalScheme}/>
                </div>
            )
        } else return null

    }

    const GetErScheme = () => {
        if (rules.CreateExpenseReports === "ApprovalScheme") {
            return  (
                <div style={{paddingBottom:"20px"}}>
                    <ExpenseReportsApprovalScheme ERApprovalScheme={erApprovalScheme} approvers={erSchemeApprovers}/>
                </div>
            )
        } else return null

    }

    return (
        <div style={{padding:"20px", overflowY:"scroll", height:"calc(100%)", maxHeight: "90vh"}}>
            <div id="HeaderC" style={{paddingBottom:"10px"}}>
                <Text size="xl" view="primary" lineHeight="2xs">Права</Text>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <Text size="xs" style={{marginRight:"10px"}} view="secondary">Почта для входа</Text>
                    <Text size="xs" style={{marginRight:"10px"}} view="primary">{rules.Login}</Text>
                    <Button view="clear" size="xs" label="Скопировать ссылку" iconSize="s" iconRight={IconLink} onClick={copyLink}/>
                </div>
                <Text size="s" style={{margin:"10px 0"}} view="primary">Пользователь: {RuleDictionary.UserStatus[user?.RegistrationStatus]}</Text>
            </div>
            <div style={{paddingBottom:"20px"}}>
                <RulesList rules={rules}/>
            </div>
            <Grid cols={2} colGap="s" style={{paddingBottom:"20px"}}>
                <GridItem>
                    <Approvers approvers={approvers}/>
                </GridItem>
                <GridItem>
                    <ExpenseReportsApprovers approvers={erApprovers}/>
                </GridItem>
            </Grid>
            <GetScheme/>
            <GetErScheme/>
        </div>
    )
};

export default RulesMain;