import React from 'react';
import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";

const SapsanClass = ({Class}) => {
    //внутрь передавать AirlineRules.Class
    if (Class.IsEnabled) {
        return (
            <div>
                <Text size="s">{dictionary.sapsanValue[Class.Value]}</Text>
                <hr/>
            </div>
        )
    }
};

export default SapsanClass;