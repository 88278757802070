import React from 'react';
import { IconLoading } from '@consta/icons/IconLoading';
import { IconCheck } from '@consta/icons/IconCheck';
import { IconClose } from '@consta/icons/IconClose';
import {Text} from "@consta/uikit/Text";

const AuthStatus = ({tokenStatus}) => {

    const RenderStatus = () => {
      switch (tokenStatus){
          case (""):
              return
          case ("loading"):
              return (
                  <IconLoading view="link" size="l"/>
              )
          case ("success"):
              return (
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                      <IconCheck view="success" size="l"/>
                      <Text view="primary" size="l">Успешно</Text>
                  </div>
              )
          case ("error"):
              return (
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                      <IconClose view="alert" size="l"/>
                      <Text view="primary" size="l" style={{paddingLeft:"15px"}}>Неверный логин/пароль</Text>
                  </div>

              )
      }
    }

    return (
        <div style={{marginRight:"15px"}}>
            <RenderStatus/>
        </div>
    );
};

export default AuthStatus;