import {Text} from "@consta/uikit/Text";
import React from "react";

const Time = ({time}) => {
    //внуть передавать AirlineRules.Time
    if (time.IsEnabled) {
        return (
            <div>
                <Text size="s">Не позднее, чем за {time.Days} дней до вылета</Text>
                <hr/>
            </div>
        )
    }
}

export default Time