import React from 'react';
import RfDoc from "./RFDoc";
import ZagranRF from "./ZagranRF";
import ForeignDoc from "./ForeignDoc";
import BirthCertificate from "./BirthCertificate";
import RFDoc from "./RFDoc";
import {v4 as uuidv4} from "uuid";

const DocumentBlock = ({emp}) => {

    const docs = emp.Documents.map(doc=>{
        if (doc.Type ==="BirthCertificate") {
            return <BirthCertificate document={doc} key={uuidv4()}/>
        }
        if (doc.Type ==="DomesticPassport") {
            if (emp.Code === "RU") {
                return <RFDoc document={doc} key={uuidv4()}/>
            } return <ForeignDoc document={doc} key={uuidv4()}/>
        }
        if (doc.Type ==="ForeignPassport") {
            return <ZagranRF document={doc} key={uuidv4()}/>
        }
    })

    return (
        <div id="FooterE" style={{overflowY:"scroll", maxHeight:"146px"}}>
            {docs}
        </div>
    );
};

export default DocumentBlock;