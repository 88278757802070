import React from 'react';
import {createIcon} from '@consta/icons/Icon';


const TrasferIconSizeM = (props) => (
    <svg viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M18.2242 4.8902C18.0609 3.2937 17.8008 2.05066 17.3892 1.56208C15.6924 -0.446861 3.80604 -0.593877 2.45648 1.56208C2.12635 2.09028 1.89482 3.32892 1.73944 4.89284C1.23809 4.95182 0.847656 5.37395 0.847656 5.89071V7.84638C0.847656 8.28127 1.1232 8.64793 1.50791 8.79099C1.4168 11.8713 1.49074 15.085 1.6272 16.6832C1.6272 17.917 2.45604 17.7124 2.45604 17.7124H3.23294V19.0443C3.23294 19.5725 3.77743 19.9999 4.44825 19.9999C5.11995 19.9999 5.66444 19.5725 5.66444 19.0443V17.7124H14.7029V19.0443C14.7029 19.5725 15.2469 19.9999 15.9186 19.9999C16.5895 19.9999 17.1339 19.5725 17.1339 19.0443V17.7124H17.3892C17.3892 17.7124 18.3647 17.8462 18.4272 17.2651C18.4272 15.6713 18.5275 12.1389 18.4448 8.80463C18.8537 8.67654 19.1521 8.29976 19.1521 7.84638V5.89071C19.1526 5.36162 18.7432 4.93246 18.2242 4.8902ZM5.3594 1.84863H14.4863V3.22944H5.3594V1.84863ZM5.48969 16.1396C4.77309 16.1396 4.19251 15.5586 4.19251 14.842C4.19251 14.1258 4.77309 13.5453 5.48969 13.5453C6.20628 13.5453 6.78731 14.1258 6.78731 14.842C6.78731 15.5586 6.20673 16.1396 5.48969 16.1396ZM14.4375 16.1396C13.7213 16.1396 13.1394 15.5586 13.1394 14.842C13.1394 14.1258 13.7209 13.5453 14.4375 13.5453C15.1541 13.5453 15.7346 14.1258 15.7346 14.842C15.7351 15.5586 15.1541 16.1396 14.4375 16.1396ZM16.0868 10.4614H3.75894V3.99533H16.0868V10.4614Z'/>
    </svg>
)

const TrasferIconSizeL = (props) => (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M18.2242 4.8902C18.0609 3.2937 17.8008 2.05066 17.3892 1.56208C15.6924 -0.446861 3.80604 -0.593877 2.45648 1.56208C2.12635 2.09028 1.89482 3.32892 1.73944 4.89284C1.23809 4.95182 0.847656 5.37395 0.847656 5.89071V7.84638C0.847656 8.28127 1.1232 8.64793 1.50791 8.79099C1.4168 11.8713 1.49074 15.085 1.6272 16.6832C1.6272 17.917 2.45604 17.7124 2.45604 17.7124H3.23294V19.0443C3.23294 19.5725 3.77743 19.9999 4.44825 19.9999C5.11995 19.9999 5.66444 19.5725 5.66444 19.0443V17.7124H14.7029V19.0443C14.7029 19.5725 15.2469 19.9999 15.9186 19.9999C16.5895 19.9999 17.1339 19.5725 17.1339 19.0443V17.7124H17.3892C17.3892 17.7124 18.3647 17.8462 18.4272 17.2651C18.4272 15.6713 18.5275 12.1389 18.4448 8.80463C18.8537 8.67654 19.1521 8.29976 19.1521 7.84638V5.89071C19.1526 5.36162 18.7432 4.93246 18.2242 4.8902ZM5.3594 1.84863H14.4863V3.22944H5.3594V1.84863ZM5.48969 16.1396C4.77309 16.1396 4.19251 15.5586 4.19251 14.842C4.19251 14.1258 4.77309 13.5453 5.48969 13.5453C6.20628 13.5453 6.78731 14.1258 6.78731 14.842C6.78731 15.5586 6.20673 16.1396 5.48969 16.1396ZM14.4375 16.1396C13.7213 16.1396 13.1394 15.5586 13.1394 14.842C13.1394 14.1258 13.7209 13.5453 14.4375 13.5453C15.1541 13.5453 15.7346 14.1258 15.7346 14.842C15.7351 15.5586 15.1541 16.1396 14.4375 16.1396ZM16.0868 10.4614H3.75894V3.99533H16.0868V10.4614Z'/>
    </svg>
)
const TrasferIconSizeS = (props) => (
    <svg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M18.2242 4.8902C18.0609 3.2937 17.8008 2.05066 17.3892 1.56208C15.6924 -0.446861 3.80604 -0.593877 2.45648 1.56208C2.12635 2.09028 1.89482 3.32892 1.73944 4.89284C1.23809 4.95182 0.847656 5.37395 0.847656 5.89071V7.84638C0.847656 8.28127 1.1232 8.64793 1.50791 8.79099C1.4168 11.8713 1.49074 15.085 1.6272 16.6832C1.6272 17.917 2.45604 17.7124 2.45604 17.7124H3.23294V19.0443C3.23294 19.5725 3.77743 19.9999 4.44825 19.9999C5.11995 19.9999 5.66444 19.5725 5.66444 19.0443V17.7124H14.7029V19.0443C14.7029 19.5725 15.2469 19.9999 15.9186 19.9999C16.5895 19.9999 17.1339 19.5725 17.1339 19.0443V17.7124H17.3892C17.3892 17.7124 18.3647 17.8462 18.4272 17.2651C18.4272 15.6713 18.5275 12.1389 18.4448 8.80463C18.8537 8.67654 19.1521 8.29976 19.1521 7.84638V5.89071C19.1526 5.36162 18.7432 4.93246 18.2242 4.8902ZM5.3594 1.84863H14.4863V3.22944H5.3594V1.84863ZM5.48969 16.1396C4.77309 16.1396 4.19251 15.5586 4.19251 14.842C4.19251 14.1258 4.77309 13.5453 5.48969 13.5453C6.20628 13.5453 6.78731 14.1258 6.78731 14.842C6.78731 15.5586 6.20673 16.1396 5.48969 16.1396ZM14.4375 16.1396C13.7213 16.1396 13.1394 15.5586 13.1394 14.842C13.1394 14.1258 13.7209 13.5453 14.4375 13.5453C15.1541 13.5453 15.7346 14.1258 15.7346 14.842C15.7351 15.5586 15.1541 16.1396 14.4375 16.1396ZM16.0868 10.4614H3.75894V3.99533H16.0868V10.4614Z'/>
    </svg>
)

export const TrasferIcon = createIcon({
    s: TrasferIconSizeS,
    m: TrasferIconSizeM,
    l: TrasferIconSizeL,
    name: "TrasferIcon",
    renderType: {
        l: 'use',
        m: 'use',
        s: 'use',
    },
    color: 'mono'
})