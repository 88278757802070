import React, {useState} from 'react';
import AviaRules from "./AviaRules";
import AviaExceptions from "./AviaExceptions";
import {Collapse} from "@consta/uikit/Collapse";
import {Card} from "@consta/uikit/Card";
const AviaTPMain = ({AirlineRules}) => {
    const [isOpen, setOpen] = useState(false);



    if (AirlineRules.Apply) {
        return (
            <Card border={true} style={{width:"99%", paddingLeft:"5px"}}>
                <Collapse label={"Авиабилеты"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)}>
                    <AviaRules AirlineRules={AirlineRules}/>
                    <AviaExceptions exceptions={AirlineRules.Exceptions}/>
                </Collapse>
            </Card>

        );
    }


};

export default AviaTPMain;