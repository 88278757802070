import {Text} from "@consta/uikit/Text";
import React from "react";

const Refundable = ({refundable}) => {
    //внутрь передавать AirlineRules.Tariff.Refundable
    if (refundable) {
        return (
            <div>
                <Text size="s">Только по возвратным тарифам</Text>
                <hr/>
            </div>
        )
    }
}
export default Refundable