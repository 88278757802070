import React from 'react';
import {createIcon} from '@consta/icons/Icon';


const TrainIconSizeM = (props) => (
    <svg viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M15.3681 15.9699H16.5239C16.8778 15.9699 17.1647 15.683 17.1647 15.329V2.98122C17.1647 2.09635 16.4474 1.37903 15.5626 1.37903H12.0446V0.464355H7.95543V1.37903H4.43745C3.55258 1.37903 2.83526 2.09635 2.83526 2.98122V15.329C2.83526 15.6829 3.12219 15.9698 3.47614 15.9698H4.63196L0 19.5356H2.97748L6.60644 15.9699H13.3936L17.0225 19.5356H20L15.3681 15.9699ZM13.3936 13.9493C13.3936 13.407 13.8331 12.9674 14.3754 12.9674C14.9177 12.9674 15.3573 13.407 15.3573 13.9493C15.3573 14.4915 14.9177 14.9311 14.3754 14.9311C13.8331 14.9311 13.3936 14.4915 13.3936 13.9493ZM5.6931 14.9311C5.15085 14.9311 4.71127 14.4915 4.71127 13.9493C4.71127 13.407 5.15085 12.9674 5.6931 12.9674C6.23536 12.9674 6.67497 13.407 6.67497 13.9493C6.67497 14.4915 6.23536 14.9311 5.6931 14.9311ZM4.7502 9.85432C4.57324 9.85432 4.42977 9.71086 4.42977 9.5339V3.32887C4.42977 3.1519 4.57324 3.00844 4.7502 3.00844H15.2498C15.4268 3.00844 15.5703 3.1519 15.5703 3.32887V9.5339C15.5703 9.71086 15.4268 9.85432 15.2498 9.85432H4.7502Z'/>
    </svg>
)

const TrainIconSizeL = (props) => (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M15.3681 15.9699H16.5239C16.8778 15.9699 17.1647 15.683 17.1647 15.329V2.98122C17.1647 2.09635 16.4474 1.37903 15.5626 1.37903H12.0446V0.464355H7.95543V1.37903H4.43745C3.55258 1.37903 2.83526 2.09635 2.83526 2.98122V15.329C2.83526 15.6829 3.12219 15.9698 3.47614 15.9698H4.63196L0 19.5356H2.97748L6.60644 15.9699H13.3936L17.0225 19.5356H20L15.3681 15.9699ZM13.3936 13.9493C13.3936 13.407 13.8331 12.9674 14.3754 12.9674C14.9177 12.9674 15.3573 13.407 15.3573 13.9493C15.3573 14.4915 14.9177 14.9311 14.3754 14.9311C13.8331 14.9311 13.3936 14.4915 13.3936 13.9493ZM5.6931 14.9311C5.15085 14.9311 4.71127 14.4915 4.71127 13.9493C4.71127 13.407 5.15085 12.9674 5.6931 12.9674C6.23536 12.9674 6.67497 13.407 6.67497 13.9493C6.67497 14.4915 6.23536 14.9311 5.6931 14.9311ZM4.7502 9.85432C4.57324 9.85432 4.42977 9.71086 4.42977 9.5339V3.32887C4.42977 3.1519 4.57324 3.00844 4.7502 3.00844H15.2498C15.4268 3.00844 15.5703 3.1519 15.5703 3.32887V9.5339C15.5703 9.71086 15.4268 9.85432 15.2498 9.85432H4.7502Z'/>
    </svg>
)
const TrainIconSizeS = (props) => (
    <svg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M15.3681 15.9699H16.5239C16.8778 15.9699 17.1647 15.683 17.1647 15.329V2.98122C17.1647 2.09635 16.4474 1.37903 15.5626 1.37903H12.0446V0.464355H7.95543V1.37903H4.43745C3.55258 1.37903 2.83526 2.09635 2.83526 2.98122V15.329C2.83526 15.6829 3.12219 15.9698 3.47614 15.9698H4.63196L0 19.5356H2.97748L6.60644 15.9699H13.3936L17.0225 19.5356H20L15.3681 15.9699ZM13.3936 13.9493C13.3936 13.407 13.8331 12.9674 14.3754 12.9674C14.9177 12.9674 15.3573 13.407 15.3573 13.9493C15.3573 14.4915 14.9177 14.9311 14.3754 14.9311C13.8331 14.9311 13.3936 14.4915 13.3936 13.9493ZM5.6931 14.9311C5.15085 14.9311 4.71127 14.4915 4.71127 13.9493C4.71127 13.407 5.15085 12.9674 5.6931 12.9674C6.23536 12.9674 6.67497 13.407 6.67497 13.9493C6.67497 14.4915 6.23536 14.9311 5.6931 14.9311ZM4.7502 9.85432C4.57324 9.85432 4.42977 9.71086 4.42977 9.5339V3.32887C4.42977 3.1519 4.57324 3.00844 4.7502 3.00844H15.2498C15.4268 3.00844 15.5703 3.1519 15.5703 3.32887V9.5339C15.5703 9.71086 15.4268 9.85432 15.2498 9.85432H4.7502Z'/>
    </svg>
)

export const TrainIcon = createIcon({
    s: TrainIconSizeS,
    m: TrainIconSizeM,
    l: TrainIconSizeL,
    name: "TrainIcon",
    renderType: {
        l: 'use',
        m: 'use',
        s: 'use',
    },
    color: 'mono'
})