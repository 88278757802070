import React, {useState} from 'react';
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {v4 as uuidv4} from "uuid";
import classes from "../../../CardWithHover.module.css";

const ResultItem = ({employee, createTab}) => {


    const id = employee.Id
    const handleCardClick = () => {
        createTab(`👤 - ${id}`, `/EmployeePage/${id}`)
    }

    const companies = employee.Companies.map(c=>{
        return (
            <Text key={uuidv4()} view="brand">{c.ShortCompanyName} ({c.CompanyId})</Text>
        )
    })

    return (
        <div onClick={handleCardClick} className={classes.Item}>
            <Card style={{padding:"15px", marginBottom:"10px"}}>
                <Grid cols="2">
                    <GridItem>
                        <Text view="primary">{employee.Surname} {employee.Name} {employee.Patronymic} ({employee.Id})</Text>
                        <Text view="linkMinor">{employee.AccountName || "не указано"} ({employee.AccountId})</Text>
                    </GridItem>
                    <GridItem>
                        {companies}
                    </GridItem>
                </Grid>
            </Card>
        </div>
    );
};

export default ResultItem;