import React, {useState} from 'react';
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";
import {Collapse} from "@consta/uikit/Collapse";
import Routes from "../AviaTP/Components/Routes";
import AviaExceptRuleList from "../AviaTP/AviaExceptRuleList";
import TrainExceptRuleList from "./TrainExceptRuleList";

const TrainExceptions = ({exceptions}) => {

    const exceptionItem = exceptions.map((ex)=>{
        const [isOpen, setOpen] = useState(false);
        return (
            <div key={exceptions.indexOf(ex)}>
                <Collapse
                    label={<><Text>Для направлений по городам:</Text><Routes comp={ex}/></>}
                    isOpen={isOpen}
                    onClick={()=>setOpen(!isOpen)}
                    hoverEffect
                    form="default"
                >
                    <TrainExceptRuleList ex={ex}/>
                </Collapse>
            </div>
        )
    })

    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l" style={{marginBottom:"10px"}}>
                    Исключения
                </Text>
                <div>
                    {exceptionItem}
                </div>
            </Card>
        </div>
    );
};

export default TrainExceptions;