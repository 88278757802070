import React, {useContext} from 'react';
import {TextField} from "@consta/uikit/TextField";
import {IconSearchStroked} from "@consta/icons/IconSearchStroked";
import {IconArrowPrevious} from "@consta/icons/IconArrowPrevious";
import {IconClose} from "@consta/icons/IconClose";
import {IconClear} from "@consta/icons/IconClear";
import {TabContext} from "../../../utils/TabContext";
import ResultItem from "./ResultItem";
import {v4 as uuidv4} from "uuid";

import {Button} from "@consta/uikit/Button";
import {FieldGroup} from "@consta/uikit/FieldGroup";
import {Loader} from "@consta/uikit/Loader";
import {Text} from "@consta/uikit/Text";
import {Chips} from "@consta/uikit/Chips";
import useSearch from "../../../utils/useSearch";
import useSandbox from "../../../utils/useSandbox";
const EmployeeSearch = () => {

    const {getSandboxStatus} = useSandbox()
    const isSandbox = getSandboxStatus()

    // https://apib.smartway.today/employees/find -> {"query": "Запрос поиска"}

    // компонент с блоком поиска по сотрудникам. тут только визуал, который можно скопироовать и адаптироваь для блоков других поисков.
    // вся логика поиска и истории в хуке useSearch

    const {fieldStatus, searchResult, value, setValue, doSearch, handeIdButtonClick, handleEnterClick, historyItems, handleRemoveHistoryItem, clearHistory} = useSearch(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/employees/find`, "Employee")


    const createTab = useContext(TabContext)

    const GetResultItems = () => {
      if(searchResult==="loading"){
        return (
            <div style={{height:"100%", width:"100%", display: "flex", alignItems:"center", justifyContent:"center"}}>
                <Loader/>
            </div>
        )
      } else if (searchResult==="error") {
          return (
              <div style={{height:"100%", width:"100%", display: "flex", justifyContent:"center"}}>
                  <Text view="primary" size="xl">Ошибка поиска</Text>
              </div>
          )
      }
      return searchResult.map(res=>{
          return <ResultItem key={uuidv4()} employee={res} createTab={createTab}/>
        })
    }


    return (
        <div style={{padding:"10px 15px 0px 15px", height:"1px", minHeight:"100%"}}>
            <FieldGroup size="l">
                <TextField
                    status={fieldStatus}
                    placeholder="Поиск по сотрудникам"
                    value={value}
                    onChange={setValue}
                    onKeyPress={handleEnterClick}
                    size="l"
                    type="search"
                    style={{paddingBottom:"15px"}}
                />
                <Button view="secondary" iconLeft={IconSearchStroked} label="Найти" onClick={doSearch}/>
                <Button view="secondary"  iconLeft={IconArrowPrevious} label="ID" onClick={()=>handeIdButtonClick(createTab)}/>
            </FieldGroup>

            <div style={{overflowY:"scroll", height:"75%"}}>
                <div style={{display:"flex", flexDirection:"row", marginBottom:"10px", alignItems: "center"}}>
                    <Text view="secondary" style={{marginRight:"20px"}}>История поиска</Text>
                    <Button view="clear" label="Очистить" onClick={clearHistory} size="s" iconLeft={IconClear}/>
                </div>
                <Chips
                    items={historyItems}
                    size='xs'
                    interactive
                    onItemClick={(item) => {
                        setValue(item.label)
                    }}
                    getItemIconRight={() => IconClose}
                    onItemRightIconClick={handleRemoveHistoryItem}
                    style={{marginBottom:"15px"}}
                />
                <GetResultItems/>
            </div>
        </div>
    );
};

export default EmployeeSearch;