import React, {useEffect, useState} from 'react';
import {Collapse} from "@consta/uikit/Collapse";
import {Text} from "@consta/uikit/Text";
import axios from "axios";
import ExpenseReportApprovalStep from "./ExpenseReportApprovalStep";
import {useApiGET} from "../../../../utils/useAPI";

const ExpenseReportsApprovalScheme = ({ERApprovalScheme, approvers}) => {
    const [isOpen, setOpen] = useState(false);

    // ФИО участников схемы полуаем по запросу https://apib.smartway.today/users/usercompanies?userId=1dd3fd93-860f-4edc-844d-4c4f602e8588
    // в конце айди пользователя-участника схемы, его берем из запроса ниже
    // https://apib.smartway.today/users/usercompanies?userId=1dd3fd93-860f-4edc-844d-4c4f602e8588
    // в конце айди пользователя-сотрудника, чью карточку и схему мы смотрим

    if (ERApprovalScheme && approvers){
        return (
            <div>
                <Collapse label={"Схема согласования АО"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)} hoverEffect rightSide={[<Text view="secondary">{ERApprovalScheme.Name}</Text>]}>
                    <ExpenseReportApprovalStep steps={ERApprovalScheme.Steps} label="Согласование АО" approvers={approvers}/>
                </Collapse>
            </div>
        );
    }
};

export default ExpenseReportsApprovalScheme;