import React, {useContext} from 'react';
import {Text} from "@consta/uikit/Text";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {CollapseGroup} from "@consta/uikit/CollapseGroup";
import {Card} from "@consta/uikit/Card";
import {v4 as uuidv4} from "uuid";
import {TabContext} from "../../../utils/TabContext";

const CompanyMain = ({companies, project, AccountName, AccountId}) => {

    const createTab = useContext(TabContext)

    let items =[]
    for (const comp in companies) {

        let deps = companies[comp].Departments.map(dep=>{
            let head
            if (dep.HeadOfDepartmentEmployee) {
                head = {
                    name: `${dep.HeadOfDepartmentEmployee.Surname} ${dep.HeadOfDepartmentEmployee.Name} ${dep.HeadOfDepartmentEmployee.Patronymic}`,
                    id: dep.HeadOfDepartmentEmployee?.Id
                }
            } else {
                head = null
            }
            return {
                name: dep.Name,
                head: head,
            }
        })

        items.push({
            title:`${companies[comp].ShortCompanyName} (${companies[comp].CompanyId})`,
            departments: deps
        })
    }


    const getItemLabel = (items) => items.title;
    const getItemContent  = (items) => {
        return items.departments.map((dep)=>{
            return (
                <Card  style={{marginBottom:"10px", padding:"5px", width:"99%"}} key={uuidv4()}>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Text view="secondary" style={{marginRight:"10px"}}>{dep.name} </Text>
                        {dep.head ? <Text style={{flexGrow:"1"}} cursor="pointer" decoration="underline" view="ghost" onClick={()=>createTab(`👤 - ${dep.head.id}`, `/EmployeePage/${dep.head.id}`)}>{dep.head.name}</Text> : null

                        }
                    </div>
                </Card>
            )

        })
    };
    const GetProject = () => {
      if (project) {
          const GetProjectHead = () => {
              if (project.ResponsibleEmployee) {
                  let projectHead = `${project.ResponsibleEmployee.Surname} ${project.ResponsibleEmployee.Name} ${project.ResponsibleEmployee.Patronymic}`
                  let  headId = project.ResponsibleEmployee.Id
                  return (
                      <Text size="xs" view="secondary">
                          Руководитель ЦЗ - <Text as="span" size="xs" view="secondary" cursor="pointer" decoration="underline" onClick={()=>createTab(`👤 - ${headId}`, `/EmployeePage/${headId}`)}>{projectHead}</Text>
                      </Text>
                  )

              } else return <Text size="xs" view="secondary">Нет руководителя</Text>

          }


          return (
              <Grid cols="1">
                  <GridItem>
                      <Text size="l" view="primary" truncate>{project.Name}</Text>
                      <GetProjectHead/>
                  </GridItem>
              </Grid>
          )
      }

    }

    return (
        <div style={{padding:"20px", display:"flex", flexDirection:"column", justifyContent: "space-between", height:"90%", overflowY:"scroll", maxHeight: "45vh"}}>
            <div>
                <div id="HeaderC" style={{paddingBottom:"10px"}}>
                    <Text view="primary" size="xl" lineHeight="2xs" truncate>{AccountName}</Text>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Text size="xs" style={{marginRight:"10px", paddingBottom:"8px"}} view="secondary">{AccountId}</Text>
                    </div>
                </div>
                <div id="MainC" style={{}}>
                    <CollapseGroup style={{width:"99%"}}
                                   form="default"
                                   hoverEffect={true}
                                   size="l"
                                   items={items}
                                   getItemLabel={getItemLabel}
                                   getItemContent={getItemContent}
                    />
                </div>
            </div>
            <div id="FooterC">
                <GetProject/>
            </div>
        </div>
    );
};

export default CompanyMain;