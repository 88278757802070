import React, {useEffect} from 'react';
import {Text} from "@consta/uikit/Text";
import {useParams} from "react-router-dom";

const CartPage = () => {
    const params = useParams()

    useEffect(()=>{
        console.log(`Грузим данные по коризне... ${params.id}`)

    },[])

    return (
        <Text>
            Страница корзины с id - {params.id}
        </Text>
    );
};

export default CartPage;