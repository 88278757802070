import React from 'react';
import {Text} from "@consta/uikit/Text";
import dictionary from "../../../../../utils/Dictionaries/Dictionary";

const TransferClass = ({Class}) => {
    if (Class.IsEnabled) {
        return (
            <div>
                <Text size="s">Только "{dictionary.transferClass[Class.Value]}" или ниже </Text>
                <hr/>
            </div>
        );
    }
};

export default TransferClass;