import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const EditEmployee = ({editRight}) => {
    const color = {
        All: "success",
        Personal: "warning"
    }
    return (
        <div>
            <Text view="primary">Редактировать данные: <Text view={color[editRight]} weight="bold" as="span">{ruleDictionary.EditEmployee[editRight]}</Text></Text>
        </div>
    );
};

export default EditEmployee;