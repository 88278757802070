const dictionary = {
    //валюты
    currency: [
        "рублей",
        "долларов",
        "евро",
        "фунтов"
    ],
    //числа или проценты
    valueType: [
        "рублей",
        "%"
    ],
    classValue: [
        "Эконом",
        "Эконом или Бизнес-класс"
    ],
    trainClass: [
        "Только купе",
        "Купе и СВ",
        "Только плацкарт"
    ],
    sapsanValue: [
        "Эконом",
        "Эконом или Бизнес-класс"
    ],
    taxiClass: [
       "",
       "Эконом",
       "Комфорт",
       "Комфорт+",
       "Бизнес",
       "Детский",
       "Минивен",
       "Круиз",
       "Премьер",


    ],
    transferClass: [
        "",
        "Стандарт",
        "Стандарт (по платной дороге)",
        "Комфорт",
        "Комфорт (по платной дороге)",
        "Бизнес лайт",
        "Бизнес",
        "Бизнес (по платной дороге)",
        "Минивен",
        "Минивен VIP",
        "Микроавтобус",
        "Микроавтобус на 18 мест",
        "Представительский"
    ]
}

export default dictionary