import React, {useState} from 'react';
import {Card} from "@consta/uikit/Card";
import {Collapse} from "@consta/uikit/Collapse";
import TrainRules from "./TrainRules";
import TrainExceptions from "./TrainExceptions";


const TrainTPMain = ({TrainRule}) => {
    const [isOpen, setOpen] = useState(false);

    if (TrainRule.Apply) {
        return (
            <Card border={true} style={{width:"99%", paddingLeft:"5px"}}>
                <Collapse label={"Ж/Д билеты"} isOpen={isOpen} onClick={()=>setOpen(!isOpen)}>
                    <TrainRules TrainRules={TrainRule}/>
                    <TrainExceptions exceptions={TrainRule.Exceptions}/>
                </Collapse>
            </Card>

        );
    }
};

export default TrainTPMain;