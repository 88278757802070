import React from 'react';
import {useQuery} from "react-query";
import {useApiGET} from "./useAPI";
import useSandbox from "./useSandbox";

const useGetEmployee = (id) => {
    const {getSandboxStatus} = useSandbox()
    const isSandbox = getSandboxStatus()

    // получаем самого сотрудника
    const employee = useQuery(
        [`employee`, id, "main-card"],
        async () => {
            const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/employee-with-account-info/${id}`)
            console.log("Employee", r.data)
            return r.data
        },
    )
    if (employee.isSuccess)     document.title = `${employee.data.Surname} ${employee.data.Name} ${employee.data.Patronymic}`

    //получение ТП
    const travelPolicy = useQuery(
        [`employee`, id, "travelPolicy"],
        async () => {
            try {
                const r = await useApiGET(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/travel-policies-service/policy/${id}`)
                console.log("Тревел политика", r.data)
                return r.data
            } catch (e) {
                if (e.response.status === 404) return "404"
                throw new Error(e)
            }
        },
        {
            enabled: !!employee.isSuccess
        }
    )

    // получение ЦЗ
    const project = useQuery(
        ["employee", id, "account-projects"],
        async () => {
            const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/account/${employee.data.AccountId}/projects`)
            for (let i = 0; i < r.data.length; i++) {
                if (r.data[i].Id ===employee.data.ProjectId) return r.data[i]
            }
            return null
        },
        {
            enabled: !!employee.isSuccess && !!employee.data.ProjectId
        }
    )

    //получение прав
    const rules = useQuery(
        ['employee', id, "rules"],
        async () => {
            try {
                const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/account/${employee.data.AccountId}/employee/${id}/uac`)
                console.log("Права", r.data)
                return r.data
            } catch (e) {
                if (e.response.status === 404)  return "404"
                throw new Error(e)
            }
        },
        {
            enabled: !!employee.isSuccess
        }
    )

    // получение пользователя
    const user = useQuery(
        ["employee", id, "user-card"],
        async () => {
            const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/users/usercompanies?userId=${rules.data.UserId}`)
            console.log("Пользователь", r.data)
            return r.data
        },
        {
            enabled: !!rules.isSuccess && rules.data !== "404"
        }
    )

    // получение списка авторизующих лиц
    const approvers = useQuery(
        ["employee", id, "approvers"],
        async () => {
            try {
                const r = await useApiGET(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/expense-report-approval/approvers/${id}`)
                console.log('Авторизующие лица',r.data)
                return r.data
            }
            catch (e) {
                return []
            }
        },
        {
            enabled: !!employee.isSuccess && !!rules.isSuccess && rules.data !== "404"
        }
    )

    //получение авторизующих лиц по АО
    const erApprovers = useQuery(
        ["employee", id, "erApprovers"],
        async () => {
            try {
                const r = await useApiGET(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/expense-report-approval/approvers/${id}`)
                console.log("Авторизующие лица по АО", r.data)
                return r.data
            }
            catch (e) {
                return []

            }
        },
        {
            enabled: !!employee.isSuccess && !!rules.isSuccess && rules.data !== "404"
        }
    )

    // получение схемы согласования
    const approvalScheme = useQuery(
        ["employee", id, "approvalScheme"],
        async () => {
            const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/approval-scheme?employeeId=${id}`)
            console.log("Схема согласования", r.data)
            return r.data
        },
        {
            enabled: !!employee.isSuccess && !!rules.isSuccess && rules.data !== "404"
        }
    )

    // получение участников схемы
    const schemeApprovers = useQuery(
        ["employee", id, "schemeApprovers"],
        async () => {
            const r = await useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/approvers/${id}`)
            console.log("Участники схемы согласования", r.data)
            return r.data
        },
        {
            enabled: !!employee.isSuccess && !!rules.isSuccess && rules.data !== "404" && !!approvalScheme.isSuccess
        }
    )


    //получение участников схемы АО
    const getErSchemeApprovers = (erApprovalScheme) => {
        const erSchhemeApprovers = []
        const approversID =[]
        erApprovalScheme.Steps.map(step =>{
            step.Approvers.map(approver => {
                if (approversID.indexOf(approver)===-1) {
                    approversID.push(approver)
                }
            })
        })
        approversID.map(id=>{
            useApiGET(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/user-service/user/${id}`)
                .then(r=>{
                    erSchhemeApprovers.push(r.data)
                })

        })
        return erSchhemeApprovers

    }

    //получение схемы согласования АО
    const erApprovalScheme = useQuery(
        ["employee", id, "erApprovalSchemeWithApprovers"],
        async () =>{
            try {
                const r = await useApiGET(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/expense-report-approval/approval-schemes/${rules.data?.UserId}`)
                console.log("Схема согласования АО", r.data[0])
                return {scheme: r.data[0], approvers: getErSchemeApprovers(r.data[0])}
            } catch (e) {
                return {scheme: null, approvers: []}
            }
        },
        {
            enabled: !!employee.isSuccess && !!rules.isSuccess && rules.data !== "404"
        }
    )



    return {employee, travelPolicy, project, rules, user, approvers, erApprovers, approvalScheme, schemeApprovers, erApprovalScheme}
};

export default useGetEmployee;