import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import MaxPrice from "./Components/MaxPrice";
import NotGreaterPrice from "./Components/NotGreaterPrice";
import Time from "./Components/Time";
import Class from "./Components/Class";
import Refundable from "./Components/Refundable";
import IncludedBaggage from "./Components/IncludedBaggage";
import DayOff from "./Components/DayOff";

const AviaExceptRuleList = ({ex}) => {
    return (
        <div>
            <Grid cols="1">
                <GridItem>
                    <MaxPrice maxPrice={ex.MaxPrice}/>
                </GridItem>
                <GridItem>
                    <NotGreaterPrice price={ex.NotGreaterPrice}/>
                </GridItem>
                <GridItem>
                    <Time time={ex.Time}/>
                </GridItem>
                <GridItem>
                    <Class aviaClass={ex.Class}/>
                </GridItem>
                <GridItem>
                    <Refundable refundable={ex.Tariff.Refundable}/>
                </GridItem>
                <GridItem>
                    <IncludedBaggage IncludedBaggage={ex.Tariff.IncludedBaggage}/>
                </GridItem>
                <GridItem>
                    <DayOff dayOff={ex.DayOff} text="Только с вылетом и прилетом в будние дни"/>
                </GridItem>
            </Grid>
        </div>
    );
};

export default AviaExceptRuleList;