import React, {useState} from 'react';

import {useApiGET, useApiPOST} from "./useAPI";
import useSandbox from "./useSandbox";

const useAuth = () => {

    // возможные состояния: авторизации не было (токен постой), успешно, ошибка, загрузка
    // возвращаем из хука: текущий статус авторизации, почту, под которой авторизовались, метод проверки текущего токена и метод авторизации

    const [tokenStatus, setTokenStatus ]= useState("")
    const [email, setEmail ]= useState("")

    const clientSecret = {
        prod: "xJNwPCctOpZkcVa9FcbNe9is3H8h3l65",
        sandbox: "ilUQDWB4eXfJncMBfC8sQ9s2znt7rppt"
    }


    const {getSandboxStatus} = useSandbox()

    const generateToken = (login, password) => {
        setTokenStatus("loading")
        const isSandbox = getSandboxStatus()
        useApiPOST(`https://capi.${isSandbox ? "sandbox." : ""}smartway.today/auth/token`, {
            grant_type: "password",
            client_id: 1,
            client_secret: isSandbox ? clientSecret.sandbox : clientSecret.prod,
            username:login,
            password:password
        }, false)
            .then(r=>{
                setTokenStatus("success")
                window.localStorage.setItem(`altBack-state${isSandbox ? "-sandbox" : ""}`, JSON.stringify(r.data))
                window.localStorage.setItem(`access${isSandbox ? "-sandbox" : ""}`, r.data.access_token)

            })
            .catch(e=> {
                window.localStorage.setItem(`altBack-state${isSandbox ? "-sandbox" : ""}`, "")
                window.localStorage.setItem(`access${isSandbox ? "-sandbox" : ""}`, "")
                console.error(e)
                setTokenStatus("error")
            })
    }

    const ping = () => {
        const isSandbox = getSandboxStatus()

        if (!window.localStorage.getItem(`access${isSandbox ? "-sandbox" : ""}`)) {
            setTokenStatus("error")
            return
        }

        setTokenStatus("loading")

        useApiGET(`https://apib.${isSandbox ? "sandbox." : ""}smartway.today/email`)
            .then(r=>{
              setTokenStatus("success")
              setEmail(r.data)
            })
            .catch(e=>{
              console.error(e)
              setEmail("")
              setTokenStatus("error")
            })
    }


    return {tokenStatus, email, ping, generateToken}
};

export default useAuth;