import React from 'react';
import {createIcon} from '@consta/icons/Icon';


const VipHallIconSizeM = (props) => (
    <svg viewBox='0 0 34 34' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d="M30.73,19.42H2.33A2.33,2.33,0,0,1,0,17.09V12A2.33,2.33,0,0,1,2.33,9.63h28.4A2.33,2.33,0,0,1,33.06,12v5.13A2.34,2.34,0,0,1,30.73,19.42ZM2.33,10.69A1.27,1.27,0,0,0,1.06,12v5.13a1.27,1.27,0,0,0,1.27,1.27h28.4A1.27,1.27,0,0,0,32,17.09V12a1.27,1.27,0,0,0-1.27-1.27Z"/>
        <path d="M4.1.53H14.88a1.65,1.65,0,0,1,1.65,1.65h0v8H2.45v-8A1.65,1.65,0,0,1,4.1.53Z"/>
        <path d="M17.06,10.71H1.92V2.18A2.19,2.19,0,0,1,4.1,0H14.88a2.19,2.19,0,0,1,2.18,2.18ZM3,9.65H16V2.18a1.12,1.12,0,0,0-1.12-1.12H4.1A1.12,1.12,0,0,0,3,2.18Z"/>
        <path d="M18.23.53H29a1.65,1.65,0,0,1,1.65,1.65h0v8H16.58v-8A1.65,1.65,0,0,1,18.23.53Z"/>
        <path d="M31.18,10.71H16.05V2.18A2.19,2.19,0,0,1,18.23,0H29a2.19,2.19,0,0,1,2.18,2.18ZM17.11,9.65h13V2.18A1.12,1.12,0,0,0,29,1.06H18.23a1.12,1.12,0,0,0-1.12,1.12Z"/>
        <path d="M5.36,10.7h-4A1.37,1.37,0,0,1,0,9.33V7.91A1.37,1.37,0,0,1,1.37,6.54h4A1.37,1.37,0,0,1,6.73,7.91V9.33A1.37,1.37,0,0,1,5.36,10.7Zm-4-3.1a.31.31,0,0,0-.31.31V9.33a.31.31,0,0,0,.31.31h4a.31.31,0,0,0,.31-.31V7.91a.31.31,0,0,0-.31-.31Z"/>
        <path d="M2.67,18.85H7.32v1.2a1.08,1.08,0,0,1-1.08,1.08H3.75a1.08,1.08,0,0,1-1.08-1.08h0Z"/>
        <path d="M6.24,21.66H3.75a1.61,1.61,0,0,1-1.61-1.61V18.32H7.85v1.73A1.61,1.61,0,0,1,6.24,21.66Zm-3-2.28v.67a.55.55,0,0,0,.55.55H6.24a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M25.53,18.85h4.65v1.2a1.08,1.08,0,0,1-1.08,1.08H26.62a1.08,1.08,0,0,1-1.09-1Z"/>
        <path d="M26.6,21.66A1.61,1.61,0,0,1,25,20.17V18.32h5.71v1.73a1.61,1.61,0,0,1-1.61,1.61H26.6Zm-.54-2.28v.75a.52.52,0,0,0,.55.47H29.1a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M.53,13.91h32v2.15a.38.38,0,0,1-.37.39H.92a.38.38,0,0,1-.39-.37V13.91Z"/>
        <path d="M.9,17a.91.91,0,0,1-.9-.89V13.38H33.06v2.68a.91.91,0,0,1-.89.92H.9ZM32,16.07ZM.91,15.92Zm.15-1.48v1.48H32V14.44Z"/>
    </svg>
)

const VipHallIconSizeL = (props) => (
    <svg viewBox='0 0 33 33' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d="M30.73,19.42H2.33A2.33,2.33,0,0,1,0,17.09V12A2.33,2.33,0,0,1,2.33,9.63h28.4A2.33,2.33,0,0,1,33.06,12v5.13A2.34,2.34,0,0,1,30.73,19.42ZM2.33,10.69A1.27,1.27,0,0,0,1.06,12v5.13a1.27,1.27,0,0,0,1.27,1.27h28.4A1.27,1.27,0,0,0,32,17.09V12a1.27,1.27,0,0,0-1.27-1.27Z"/>
        <path d="M4.1.53H14.88a1.65,1.65,0,0,1,1.65,1.65h0v8H2.45v-8A1.65,1.65,0,0,1,4.1.53Z"/>
        <path d="M17.06,10.71H1.92V2.18A2.19,2.19,0,0,1,4.1,0H14.88a2.19,2.19,0,0,1,2.18,2.18ZM3,9.65H16V2.18a1.12,1.12,0,0,0-1.12-1.12H4.1A1.12,1.12,0,0,0,3,2.18Z"/>
        <path d="M18.23.53H29a1.65,1.65,0,0,1,1.65,1.65h0v8H16.58v-8A1.65,1.65,0,0,1,18.23.53Z"/>
        <path d="M31.18,10.71H16.05V2.18A2.19,2.19,0,0,1,18.23,0H29a2.19,2.19,0,0,1,2.18,2.18ZM17.11,9.65h13V2.18A1.12,1.12,0,0,0,29,1.06H18.23a1.12,1.12,0,0,0-1.12,1.12Z"/>
        <path d="M5.36,10.7h-4A1.37,1.37,0,0,1,0,9.33V7.91A1.37,1.37,0,0,1,1.37,6.54h4A1.37,1.37,0,0,1,6.73,7.91V9.33A1.37,1.37,0,0,1,5.36,10.7Zm-4-3.1a.31.31,0,0,0-.31.31V9.33a.31.31,0,0,0,.31.31h4a.31.31,0,0,0,.31-.31V7.91a.31.31,0,0,0-.31-.31Z"/>
        <path d="M2.67,18.85H7.32v1.2a1.08,1.08,0,0,1-1.08,1.08H3.75a1.08,1.08,0,0,1-1.08-1.08h0Z"/>
        <path d="M6.24,21.66H3.75a1.61,1.61,0,0,1-1.61-1.61V18.32H7.85v1.73A1.61,1.61,0,0,1,6.24,21.66Zm-3-2.28v.67a.55.55,0,0,0,.55.55H6.24a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M25.53,18.85h4.65v1.2a1.08,1.08,0,0,1-1.08,1.08H26.62a1.08,1.08,0,0,1-1.09-1Z"/>
        <path d="M26.6,21.66A1.61,1.61,0,0,1,25,20.17V18.32h5.71v1.73a1.61,1.61,0,0,1-1.61,1.61H26.6Zm-.54-2.28v.75a.52.52,0,0,0,.55.47H29.1a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M.53,13.91h32v2.15a.38.38,0,0,1-.37.39H.92a.38.38,0,0,1-.39-.37V13.91Z"/>
        <path d="M.9,17a.91.91,0,0,1-.9-.89V13.38H33.06v2.68a.91.91,0,0,1-.89.92H.9ZM32,16.07ZM.91,15.92Zm.15-1.48v1.48H32V14.44Z"/>
    </svg>
)
const VipHallIconSizeS = (props) => (
    <svg viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d="M30.73,19.42H2.33A2.33,2.33,0,0,1,0,17.09V12A2.33,2.33,0,0,1,2.33,9.63h28.4A2.33,2.33,0,0,1,33.06,12v5.13A2.34,2.34,0,0,1,30.73,19.42ZM2.33,10.69A1.27,1.27,0,0,0,1.06,12v5.13a1.27,1.27,0,0,0,1.27,1.27h28.4A1.27,1.27,0,0,0,32,17.09V12a1.27,1.27,0,0,0-1.27-1.27Z"/>
        <path d="M4.1.53H14.88a1.65,1.65,0,0,1,1.65,1.65h0v8H2.45v-8A1.65,1.65,0,0,1,4.1.53Z"/>
        <path d="M17.06,10.71H1.92V2.18A2.19,2.19,0,0,1,4.1,0H14.88a2.19,2.19,0,0,1,2.18,2.18ZM3,9.65H16V2.18a1.12,1.12,0,0,0-1.12-1.12H4.1A1.12,1.12,0,0,0,3,2.18Z"/>
        <path d="M18.23.53H29a1.65,1.65,0,0,1,1.65,1.65h0v8H16.58v-8A1.65,1.65,0,0,1,18.23.53Z"/>
        <path d="M31.18,10.71H16.05V2.18A2.19,2.19,0,0,1,18.23,0H29a2.19,2.19,0,0,1,2.18,2.18ZM17.11,9.65h13V2.18A1.12,1.12,0,0,0,29,1.06H18.23a1.12,1.12,0,0,0-1.12,1.12Z"/>
        <path d="M5.36,10.7h-4A1.37,1.37,0,0,1,0,9.33V7.91A1.37,1.37,0,0,1,1.37,6.54h4A1.37,1.37,0,0,1,6.73,7.91V9.33A1.37,1.37,0,0,1,5.36,10.7Zm-4-3.1a.31.31,0,0,0-.31.31V9.33a.31.31,0,0,0,.31.31h4a.31.31,0,0,0,.31-.31V7.91a.31.31,0,0,0-.31-.31Z"/>
        <path d="M2.67,18.85H7.32v1.2a1.08,1.08,0,0,1-1.08,1.08H3.75a1.08,1.08,0,0,1-1.08-1.08h0Z"/>
        <path d="M6.24,21.66H3.75a1.61,1.61,0,0,1-1.61-1.61V18.32H7.85v1.73A1.61,1.61,0,0,1,6.24,21.66Zm-3-2.28v.67a.55.55,0,0,0,.55.55H6.24a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M25.53,18.85h4.65v1.2a1.08,1.08,0,0,1-1.08,1.08H26.62a1.08,1.08,0,0,1-1.09-1Z"/>
        <path d="M26.6,21.66A1.61,1.61,0,0,1,25,20.17V18.32h5.71v1.73a1.61,1.61,0,0,1-1.61,1.61H26.6Zm-.54-2.28v.75a.52.52,0,0,0,.55.47H29.1a.55.55,0,0,0,.55-.55v-.67Z"/>
        <path d="M.53,13.91h32v2.15a.38.38,0,0,1-.37.39H.92a.38.38,0,0,1-.39-.37V13.91Z"/>
        <path d="M.9,17a.91.91,0,0,1-.9-.89V13.38H33.06v2.68a.91.91,0,0,1-.89.92H.9ZM32,16.07ZM.91,15.92Zm.15-1.48v1.48H32V14.44Z"/>
    </svg>
)

export const VipHallIcon = createIcon({
    s: VipHallIconSizeS,
    m: VipHallIconSizeM,
    l: VipHallIconSizeL,
    name: "VipHallIcon",
    renderType: {
        l: 'use',
        m: 'use',
        s: 'use',
    },
    color: 'mono'
})