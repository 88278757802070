import React from 'react';
import {Text} from "@consta/uikit/Text";
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";

const Finance = ({fin}) => {
    const color = {
        Available: "success",
        Unavailable: "alert"
    }
    return (
        <div>
            <Text view="primary">Финансовая отчетность: <Text view={color[fin]} weight="bold" as="span">{ruleDictionary.Finance[fin]}</Text></Text>
        </div>
    );
};

export default Finance;