import React, {useState} from 'react';
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";
import Routes from "./Components/Routes";
import AviaExceptRuleList from "./AviaExceptRuleList";
import {Collapse} from "@consta/uikit/Collapse";

const AviaExceptions = ({exceptions}) => {
    //внутрь передавать AviaRules.Exceptions


    const exceptionItem = exceptions.map((ex)=>{
        if (ex.Type===1) {
            const [isOpen, setOpen] = useState(false);

            return (
                <div key={exceptions.indexOf(ex)}>
                    <Collapse
                        label={<><Text>Для направлений по городам:</Text><Routes comp={ex}/></>}
                        isOpen={isOpen}
                        onClick={()=>setOpen(!isOpen)}
                        hoverEffect
                        form="default"
                    >
                        <AviaExceptRuleList ex={ex}/>
                    </Collapse>
                </div>
            )
        }
        const [isOpen, setOpen] = useState(false);
        return (
            <div key={exceptions.indexOf(ex)}>
                <Collapse
                    label={<><Text>Для перелетов дольше {ex.Hours} часов</Text></>}
                    isOpen={isOpen}
                    onClick={()=>setOpen(!isOpen)}
                    hoverEffect
                    form="default"
                >
                    <AviaExceptRuleList ex={ex}/>
                </Collapse>
            </div>
        )
    })
    return (
        <div>
            <Card style={{marginBottom:"10px", padding:"5px", width:"99%"}}>
                <Text weight="semibold" size="l" style={{marginBottom:"10px"}}>
                    Исключения
                </Text>
                <div>
                    {exceptionItem}
                </div>
            </Card>
        </div>
    );
};

export default AviaExceptions;