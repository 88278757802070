import React from 'react';

import {Responses404} from "@consta/uikit/Responses404";

const NotFoundPage = () => {
    return (
        <div style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Responses404 size="l" description="Возможно, в вашей ссылке ошибка" actions={<></>}/>
        </div>
    );
};

export default NotFoundPage;