import React from 'react';
import {createIcon} from '@consta/icons/Icon';


const AviaIconSizeM = (props) => (
    <svg viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M19.262 0.0487989L17.1457 1.01211C17.0101 1.07466 16.885 1.16015 16.7787 1.26649L13.3299 4.71732L1.45329 3.2932C1.23852 3.26818 1.02167 3.34116 0.869462 3.49545L0.20849 4.15643C-0.141805 4.50672 -0.0333807 5.09889 0.419084 5.30114L8.93041 9.11685L6.48669 11.5606H2.21851C2.03086 11.5606 1.85154 11.6356 1.71809 11.767L1.36154 12.1256C1.00708 12.4801 1.12384 13.0785 1.58256 13.2745L5.18351 14.8175L6.72648 18.4184C6.92247 18.8771 7.52298 18.9939 7.87538 18.6395L8.23399 18.2808C8.36744 18.1474 8.44042 17.9681 8.44042 17.7804V13.5122L10.8841 11.0685L14.6999 19.5819C14.9021 20.0344 15.4943 20.1428 15.8445 19.7925L16.5055 19.1316C16.6598 18.9773 16.7328 18.7625 16.7078 18.5477L15.2816 6.67107L18.7324 3.22024C18.8388 3.1139 18.9242 2.99088 18.9868 2.85327L19.9501 0.736899C20.1524 0.301095 19.702 -0.15137 19.262 0.0487989Z'/>
    </svg>
)

const AviaIconSizeL = (props) => (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M19.262 0.0487989L17.1457 1.01211C17.0101 1.07466 16.885 1.16015 16.7787 1.26649L13.3299 4.71732L1.45329 3.2932C1.23852 3.26818 1.02167 3.34116 0.869462 3.49545L0.20849 4.15643C-0.141805 4.50672 -0.0333807 5.09889 0.419084 5.30114L8.93041 9.11685L6.48669 11.5606H2.21851C2.03086 11.5606 1.85154 11.6356 1.71809 11.767L1.36154 12.1256C1.00708 12.4801 1.12384 13.0785 1.58256 13.2745L5.18351 14.8175L6.72648 18.4184C6.92247 18.8771 7.52298 18.9939 7.87538 18.6395L8.23399 18.2808C8.36744 18.1474 8.44042 17.9681 8.44042 17.7804V13.5122L10.8841 11.0685L14.6999 19.5819C14.9021 20.0344 15.4943 20.1428 15.8445 19.7925L16.5055 19.1316C16.6598 18.9773 16.7328 18.7625 16.7078 18.5477L15.2816 6.67107L18.7324 3.22024C18.8388 3.1139 18.9242 2.99088 18.9868 2.85327L19.9501 0.736899C20.1524 0.301095 19.702 -0.15137 19.262 0.0487989Z'/>
    </svg>
)
const AviaIconSizeS = (props) => (
    <svg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M19.262 0.0487989L17.1457 1.01211C17.0101 1.07466 16.885 1.16015 16.7787 1.26649L13.3299 4.71732L1.45329 3.2932C1.23852 3.26818 1.02167 3.34116 0.869462 3.49545L0.20849 4.15643C-0.141805 4.50672 -0.0333807 5.09889 0.419084 5.30114L8.93041 9.11685L6.48669 11.5606H2.21851C2.03086 11.5606 1.85154 11.6356 1.71809 11.767L1.36154 12.1256C1.00708 12.4801 1.12384 13.0785 1.58256 13.2745L5.18351 14.8175L6.72648 18.4184C6.92247 18.8771 7.52298 18.9939 7.87538 18.6395L8.23399 18.2808C8.36744 18.1474 8.44042 17.9681 8.44042 17.7804V13.5122L10.8841 11.0685L14.6999 19.5819C14.9021 20.0344 15.4943 20.1428 15.8445 19.7925L16.5055 19.1316C16.6598 18.9773 16.7328 18.7625 16.7078 18.5477L15.2816 6.67107L18.7324 3.22024C18.8388 3.1139 18.9242 2.99088 18.9868 2.85327L19.9501 0.736899C20.1524 0.301095 19.702 -0.15137 19.262 0.0487989Z'/>
    </svg>
)

export const AviaIcon = createIcon({
    s: AviaIconSizeS,
    m: AviaIconSizeM,
    l: AviaIconSizeL,
    name: "AviaIcon",
    renderType: {
        l: 'use',
        m: 'use',
        s: 'use',
    },
    color: 'mono'
})


