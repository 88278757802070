import React from 'react';
import ruleDictionary from "../../../../utils/Dictionaries/RuleDictionary";
import {Text} from "@consta/uikit/Text";

const ViewTrips = ({viewTrips}) => {
    const color = {
        All:"success",
        Personal:"warning",
        Undefined:  "alert"
    }
    return (
        <div>
            <Text view="primary">Просмотр поездок: <Text as="span" view={color[viewTrips]} weight="bold">{ruleDictionary.ViewTrips[viewTrips]}</Text> </Text>
        </div>
    );
};

export default ViewTrips;